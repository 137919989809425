.car-marketing-special-offer {
  @include inset-panel($panel-color: $color-package-special);

  margin-bottom: $site-margin;
  padding: calc($site-margin / 2) $site-margin;

  .car-marketing-special-offer-header {
    margin: 0;
    margin-bottom: $site-margin-half;
    color: $color-package-special;
    font-size: 1.1em;
    font-weight: bold;
  }

  .car-marketing-item-icon {
    color: $color-package-special;
  }
}

.car-marketing-coronavirus {
  .car-marketing-item-content {
    color: $color-package-special;
    font-weight: bold;
  }
}

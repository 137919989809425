$color-breadcrumbs-background: $color-background-view !default;
$color-breadcrumb: $color-grey-300 !default;
$color-breadcrumb-text: contrast-color($color-breadcrumb) !default;
$color-breadcrumb-active: $color-partner-secondary !default;
$color-breadcrumb-active-text: contrast-color($color-breadcrumb-active) !default;
$color-breadcrumb-complete: $color-partner-primary !default;
$color-breadcrumb-complete-text: contrast-color($color-breadcrumb-complete) !default;
$color-breadcrumb-spacer: $color-breadcrumbs-background !default;

$breadcrumbs-height: 38px !default;
$breadcrumbs-arrow-width: calc(($breadcrumbs-height * 1.414213) / 2); // stylelint-disable-line

.breadcrumbs-panel {
  @include outer-container;

  background: $color-breadcrumbs-background;
  padding-top: 3px;
  padding-bottom: $site-margin;
}

.breadcrumbs-normal {
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;

  .breadcrumb {
    display: table-cell;
    width: 20%;
    height: 38px;

    .caption {
      position: relative;
      z-index: 20;
      overflow: hidden;
      font-size: 0.9rem;

      @include media($media-mobile) {
        display: none;
      }
    }

    .step {
      display: inline;
      position: relative;
      z-index: 20;
      padding: 0 6px;
      font-size: 0.8rem;
      font-weight: bold;
    }

    &:last-of-type {
      padding-right: $breadcrumbs-arrow-width;
    }
  }

  .sequent {
    display: block;
    position: relative;
    float: left;
    background-color: $color-breadcrumb;
    padding-left: $breadcrumbs-arrow-width;
    width: 100%;

    // min-width: $breadcrumbs-height;
    height: 100%;
    text-align: center;
    text-decoration: none;
    line-height: $breadcrumbs-height;
    white-space: nowrap;
    color: $color-breadcrumb-text;

    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: calc($breadcrumbs-height / -2);
      transform: rotate(45deg);
      z-index: 2;
      border-top: 1px solid $color-breadcrumb-spacer;
      border-right: 1px solid $color-breadcrumb-spacer;
      border-bottom-left-radius: $breadcrumbs-height;
      background: $color-breadcrumb;
      width: 38px;
      height: 38px;
      content: ' ';
    }

    .complete &::after {
      background: $color-breadcrumb-complete;
    }

    .active &::after {
      background: $color-breadcrumb-active;
    }
  }

  .active {
    .sequent {
      background: $color-breadcrumb-active;
      color: $color-breadcrumb-active-text;
    }

    .sequent::after {
      background: $color-breadcrumb-active;
    }
  }

  .complete {
    .sequent {
      background: $color-breadcrumb-complete;
      color: $color-breadcrumb-complete-text;
    }

    .sequent::after {
      background: $color-breadcrumb-complete;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @include media($media-mobile) {
    width: 100%;

    .active {
      width: 80%;

      .caption {
        display: inline;
        font-size: 0.8em;
      }
    }
  }
}

// hide breadcrumbs on search loading page
.search-breadcrumbs-wrapper {
  display: none;

  &.loaded {
    display: block;
  }
}

$color-rate-details-border: $color-grey-300 !default;
$rate-details-border-radius: $site-border-radius !default;
$color-rate-details-section-background: $color-background-panel !default;
$color-rate-details-link: $color-link !default;
$color-rate-details-icon: $color-colored-icon !default;
$color-rate-details-header-background: $color-background-panel-subdued !default;

.rate-location-details {
  @include basic-panel;
  @include outer-container;

  background: $color-rate-details-section-background;
  overflow: hidden;
}

.pickup-location-header,
.dropoff-location-header,
.locations-header {
  @include span-columns(12);

  background: $color-rate-details-header-background;
  cursor: pointer;
  padding: $site-margin;
}

.pickup-location-header,
.dropoff-location-header {
  @include media($media-not-portable) {
    display: none;
  }
}

.locations-header {
  @include media($media-portable) {
    display: none;
  }
}

.rate-location-details-section {
  background: $color-rate-details-section-background;
  padding: $site-margin;

  @include media($media-not-portable) {
    @include span-columns(6);
  }

  @include media($media-portable) {
    @include span-columns(12);
  }
}

.location-address-toggle {
  @include accordion-toggle;

  float: right;
  color: $color-rate-details-link;
  font-size: 0.85em;

  .hide-address {
    display: none;
  }
}

.locations-header.open,
.rate-location-details.open,
.rate-location-details-section.open {
  .rate-location-details-toggle-icon {
    transform: rotate(180deg);
  }

  .hide-address {
    display: block;
  }

  .show-address {
    display: none;
  }

  .address {
    display: block;
  }
}

.location-details {
  float: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;

  .location-detail-time,
  .location-detail {
    float: left;
    margin-bottom: $site-margin;

    i {
      float: left;
      margin-right: 4px;
      text-align: center;
      color: $color-rate-details-icon;
    }

    @include media($media-portable) {
      @include span-columns(12);
    }
  }

  .location-detail-time {
    clear: left;
    margin-bottom: 0;
  }

  .location-address-text {
    display: block;
    overflow: hidden;
    line-height: 18px;
  }

  .location-detail-text {
    float: left;
    margin: 0 $site-margin 0 0;
    line-height: 18px;
  }
}

.address {
  @include accordion-content;

  margin: 5px 0 0;
  line-height: 1.4;

  .address-line {
    display: block;
    margin: 0 0 2px;
  }

  .address-description {
    margin: 10px 0;
    font-size: 0.85em;
  }

  .get-directions {
    margin-top: 10px;
    text-decoration: none;
    color: $color-rate-details-link;
  }
}

.rate-location-details-subheader-text {
  float: left;
  margin: 0;
  font-size: 1em;
}

.rate-location-details-toggle-icon {
  float: right;
}

/*
 * Default size
*/

.cnx-flag {
  background-image: url('https://static.carhire-solutions.com/images/wlp/flags/flags-16.png');
  width: 16px;
  height: 11px;

  &-me {
    background-position: 0 0;
    height: 12px;
  }

  &-ch {
    background-position: -208px -187px;
    width: 11px;
  }

  &-ky {
    background-position: -16px 0;
  }

  &-af {
    background-position: 0 -12px;
  }

  &-ag {
    background-position: -16px -12px;
  }

  &-ai {
    background-position: -32px 0;
  }

  &-al {
    background-position: -32px -11px;
  }

  &-am {
    background-position: 0 -23px;
  }

  &-an {
    background-position: -16px -23px;
  }

  &-ao {
    background-position: -32px -23px;
  }

  &-aq {
    background-position: 0 -34px;
  }

  &-ar {
    background-position: -16px -34px;
  }

  &-as {
    background-position: -32px -34px;
  }

  &-at {
    background-position: -48px 0;
  }

  &-au {
    background-position: -48px -11px;
  }

  &-aw {
    background-position: -48px -22px;
  }

  &-ax {
    background-position: -48px -33px;
  }

  &-az {
    background-position: 0 -45px;
  }

  &-ba {
    background-position: -16px -45px;
  }

  &-bb {
    background-position: -32px -45px;
  }

  &-bd {
    background-position: -48px -45px;
  }

  &-be {
    background-position: -64px 0;
  }

  &-bf {
    background-position: -64px -11px;
  }

  &-bg {
    background-position: -64px -22px;
  }

  &-bh {
    background-position: -64px -33px;
  }

  &-bi {
    background-position: -64px -44px;
  }

  &-bj {
    background-position: 0 -56px;
  }

  &-bl {
    background-position: -16px -56px;
  }

  &-bm {
    background-position: -32px -56px;
  }

  &-bn {
    background-position: -48px -56px;
  }

  &-bo {
    background-position: -64px -56px;
  }

  &-bq {
    background-position: 0 -67px;
  }

  &-br {
    background-position: -16px -67px;
  }

  &-bs {
    background-position: -32px -67px;
  }

  &-bt {
    background-position: -48px -67px;
  }

  &-bv {
    background-position: -64px -67px;
  }

  &-bw {
    background-position: -80px 0;
  }

  &-by {
    background-position: -80px -11px;
  }

  &-bz {
    background-position: -80px -22px;
  }

  &-ca {
    background-position: -80px -33px;
  }

  &-cc {
    background-position: -80px -44px;
  }

  &-cd {
    background-position: -80px -55px;
  }

  &-cf {
    background-position: -80px -66px;
  }

  &-cg {
    background-position: 0 -78px;
  }

  &-zm {
    background-position: -16px -78px;
  }

  &-ci {
    background-position: -32px -78px;
  }

  &-ck {
    background-position: -48px -78px;
  }

  &-cl {
    background-position: -64px -78px;
  }

  &-cm {
    background-position: -80px -78px;
  }

  &-cn {
    background-position: -96px 0;
  }

  &-co {
    background-position: -96px -11px;
  }

  &-cr {
    background-position: -96px -22px;
  }

  &-cs {
    background-position: -96px -33px;
  }

  &-cu {
    background-position: -96px -44px;
  }

  &-cv {
    background-position: -96px -55px;
  }

  &-cw {
    background-position: -96px -66px;
  }

  &-cx {
    background-position: -96px -77px;
  }

  &-cy {
    background-position: 0 -89px;
  }

  &-cz {
    background-position: -16px -89px;
  }

  &-de {
    background-position: -32px -89px;
  }

  &-dj {
    background-position: -48px -89px;
  }

  &-dk {
    background-position: -64px -89px;
  }

  &-dm {
    background-position: -80px -89px;
  }

  &-do {
    background-position: -96px -89px;
  }

  &-dz {
    background-position: 0 -100px;
  }

  &-ec {
    background-position: -16px -100px;
  }

  &-ee {
    background-position: -32px -100px;
  }

  &-eg {
    background-position: -48px -100px;
  }

  &-eh {
    background-position: -64px -100px;
  }

  &-er {
    background-position: -80px -100px;
  }

  &-es {
    background-position: -96px -100px;
  }

  &-et {
    background-position: -112px 0;
  }

  &-fi {
    background-position: -112px -11px;
  }

  &-fj {
    background-position: -112px -22px;
  }

  &-fk {
    background-position: -112px -33px;
  }

  &-fm {
    background-position: -112px -44px;
  }

  &-fo {
    background-position: -112px -55px;
  }

  &-fr {
    background-position: -112px -66px;
  }

  &-ga {
    background-position: -112px -77px;
  }

  &-gb {
    background-position: -112px -88px;
  }

  &-gd {
    background-position: -112px -99px;
  }

  &-ge {
    background-position: 0 -111px;
  }

  &-gf {
    background-position: -16px -111px;
  }

  &-gg {
    background-position: -32px -111px;
  }

  &-gh {
    background-position: -48px -111px;
  }

  &-gi {
    background-position: -64px -111px;
  }

  &-gl {
    background-position: -80px -111px;
  }

  &-gm {
    background-position: -96px -111px;
  }

  &-gn {
    background-position: -112px -111px;
  }

  &-gp {
    background-position: -128px 0;
  }

  &-gq {
    background-position: -128px -11px;
  }

  &-gr {
    background-position: -128px -22px;
  }

  &-gs {
    background-position: -128px -33px;
  }

  &-gt {
    background-position: -128px -44px;
  }

  &-gu {
    background-position: -128px -55px;
  }

  &-gw {
    background-position: -128px -66px;
  }

  &-gy {
    background-position: -128px -77px;
  }

  &-hk {
    background-position: -128px -88px;
  }

  &-hm {
    background-position: -128px -99px;
  }

  &-hn {
    background-position: -128px -110px;
  }

  &-hr {
    background-position: 0 -122px;
  }

  &-ht {
    background-position: -16px -122px;
  }

  &-hu {
    background-position: -32px -122px;
  }

  &-id {
    background-position: -48px -122px;
  }

  &-ie {
    background-position: -64px -122px;
  }

  &-il {
    background-position: -80px -122px;
  }

  &-im {
    background-position: -96px -122px;
  }

  &-in {
    background-position: -112px -122px;
  }

  &-io {
    background-position: -128px -122px;
  }

  &-iq {
    background-position: 0 -133px;
  }

  &-ir {
    background-position: -16px -133px;
  }

  &-is {
    background-position: -32px -133px;
  }

  &-it {
    background-position: -48px -133px;
  }

  &-je {
    background-position: -64px -133px;
  }

  &-jm {
    background-position: -80px -133px;
  }

  &-jo {
    background-position: -96px -133px;
  }

  &-jp {
    background-position: -112px -133px;
  }

  &-ke {
    background-position: -128px -133px;
  }

  &-kg {
    background-position: -144px 0;
  }

  &-kh {
    background-position: -144px -11px;
  }

  &-ki {
    background-position: -144px -22px;
  }

  &-km {
    background-position: -144px -33px;
  }

  &-kn {
    background-position: -144px -44px;
  }

  &-kp {
    background-position: -144px -55px;
  }

  &-kr {
    background-position: -144px -66px;
  }

  &-kv {
    background-position: -144px -77px;
  }

  &-kw {
    background-position: -144px -88px;
  }

  &-ae {
    background-position: -144px -99px;
  }

  &-kz {
    background-position: -144px -110px;
  }

  &-la {
    background-position: -144px -121px;
  }

  &-lb {
    background-position: -144px -132px;
  }

  &-lc {
    background-position: 0 -144px;
  }

  &-li {
    background-position: -16px -144px;
  }

  &-lk {
    background-position: -32px -144px;
  }

  &-lr {
    background-position: -48px -144px;
  }

  &-ls {
    background-position: -64px -144px;
  }

  &-lt {
    background-position: -80px -144px;
  }

  &-lu {
    background-position: -96px -144px;
  }

  &-lv {
    background-position: -112px -144px;
  }

  &-ly {
    background-position: -128px -144px;
  }

  &-ma {
    background-position: -144px -144px;
  }

  &-mc {
    background-position: -160px 0;
  }

  &-md {
    background-position: -160px -11px;
  }

  &-ad {
    background-position: -160px -22px;
  }

  &-mf {
    background-position: -160px -33px;
  }

  &-mg {
    background-position: -160px -44px;
  }

  &-mh {
    background-position: -160px -55px;
  }

  &-mk {
    background-position: -160px -66px;
  }

  &-ml {
    background-position: -160px -77px;
  }

  &-mm {
    background-position: -160px -88px;
  }

  &-mn {
    background-position: -160px -99px;
  }

  &-mo {
    background-position: -160px -110px;
  }

  &-mp {
    background-position: -160px -121px;
  }

  &-mq {
    background-position: -160px -132px;
  }

  &-mr {
    background-position: -160px -143px;
  }

  &-ms {
    background-position: 0 -155px;
  }

  &-mt {
    background-position: -16px -155px;
  }

  &-mu {
    background-position: -32px -155px;
  }

  &-mv {
    background-position: -48px -155px;
  }

  &-mw {
    background-position: -64px -155px;
  }

  &-mx {
    background-position: -80px -155px;
  }

  &-my {
    background-position: -96px -155px;
  }

  &-mz {
    background-position: -112px -155px;
  }

  &-na {
    background-position: -128px -155px;
  }

  &-nc {
    background-position: -144px -155px;
  }

  &-ne {
    background-position: -160px -155px;
  }

  &-nf {
    background-position: -176px 0;
  }

  &-ng {
    background-position: -176px -11px;
  }

  &-ni {
    background-position: -176px -22px;
  }

  &-nl {
    background-position: -176px -33px;
  }

  &-no {
    background-position: -176px -44px;
  }

  &-nr {
    background-position: -176px -55px;
  }

  &-nu {
    background-position: -176px -66px;
  }

  &-nz {
    background-position: -176px -77px;
  }

  &-om {
    background-position: -176px -88px;
  }

  &-pa {
    background-position: -176px -99px;
  }

  &-pe {
    background-position: -176px -110px;
  }

  &-pf {
    background-position: -176px -121px;
  }

  &-pg {
    background-position: -176px -132px;
  }

  &-ph {
    background-position: -176px -143px;
  }

  &-pk {
    background-position: -176px -154px;
  }

  &-pl {
    background-position: 0 -166px;
  }

  &-pm {
    background-position: -16px -166px;
  }

  &-pn {
    background-position: -32px -166px;
  }

  &-pr {
    background-position: -48px -166px;
  }

  &-ps {
    background-position: -64px -166px;
  }

  &-pt {
    background-position: -80px -166px;
  }

  &-pw {
    background-position: -96px -166px;
  }

  &-py {
    background-position: -112px -166px;
  }

  &-qa {
    background-position: -128px -166px;
  }

  &-re {
    background-position: -144px -166px;
  }

  &-ro {
    background-position: -160px -166px;
  }

  &-rs {
    background-position: -176px -166px;
  }

  &-ru {
    background-position: 0 -177px;
  }

  &-rw {
    background-position: -16px -177px;
  }

  &-sa {
    background-position: -32px -177px;
  }

  &-sb {
    background-position: -48px -177px;
  }

  &-sc {
    background-position: -64px -177px;
  }

  &-sd {
    background-position: -80px -177px;
  }

  &-se {
    background-position: -96px -177px;
  }

  &-sg {
    background-position: -112px -177px;
  }

  &-sh {
    background-position: -128px -177px;
  }

  &-si {
    background-position: -144px -177px;
  }

  &-sj {
    background-position: -160px -177px;
  }

  &-sk {
    background-position: -176px -177px;
  }

  &-sl {
    background-position: -192px 0;
  }

  &-sm {
    background-position: -192px -11px;
  }

  &-sn {
    background-position: -192px -22px;
  }

  &-so {
    background-position: -192px -33px;
  }

  &-sr {
    background-position: -192px -44px;
  }

  &-ss {
    background-position: -192px -55px;
  }

  &-st {
    background-position: -192px -66px;
  }

  &-su {
    background-position: -192px -77px;
  }

  &-sv {
    background-position: -192px -88px;
  }

  &-sx {
    background-position: -192px -99px;
  }

  &-sy {
    background-position: -192px -110px;
  }

  &-sz {
    background-position: -192px -121px;
  }

  &-tc {
    background-position: -192px -132px;
  }

  &-td {
    background-position: -192px -143px;
  }

  &-tf {
    background-position: -192px -154px;
  }

  &-tg {
    background-position: -192px -165px;
  }

  &-th {
    background-position: -192px -176px;
  }

  &-tj {
    background-position: 0 -188px;
  }

  &-tk {
    background-position: -16px -188px;
  }

  &-tl {
    background-position: -32px -188px;
  }

  &-tm {
    background-position: -48px -188px;
  }

  &-tn {
    background-position: -64px -188px;
  }

  &-to {
    background-position: -80px -188px;
  }

  &-tr {
    background-position: -96px -188px;
  }

  &-tt {
    background-position: -112px -188px;
  }

  &-tv {
    background-position: -128px -188px;
  }

  &-tw {
    background-position: -144px -188px;
  }

  &-tz {
    background-position: -160px -188px;
  }

  &-ua {
    background-position: -176px -188px;
  }

  &-ug {
    background-position: -192px -188px;
  }

  &-um {
    background-position: -208px 0;
  }

  &-us {
    background-position: -208px -11px;
  }

  &-uy {
    background-position: -208px -22px;
  }

  &-uz {
    background-position: -208px -33px;
  }

  &-va {
    background-position: -208px -44px;
  }

  &-vc {
    background-position: -208px -55px;
  }

  &-ve {
    background-position: -208px -66px;
  }

  &-vg {
    background-position: -208px -77px;
  }

  &-vi {
    background-position: -208px -88px;
  }

  &-vn {
    background-position: -208px -99px;
  }

  &-vu {
    background-position: -208px -110px;
  }

  &-wf {
    background-position: -208px -121px;
  }

  &-ws {
    background-position: -208px -132px;
  }

  &-ye {
    background-position: -208px -143px;
  }

  &-yt {
    background-position: -208px -154px;
  }

  &-za {
    background-position: -208px -165px;
  }

  &-zw {
    background-position: -208px -176px;
  }
}

/*
 * Medium size
*/

.cnx-flag-medium {
  background-image: url('https://static.carhire-solutions.com/images/wlp/flags/flags-32.png');
  width: 32px;
  height: 32px;

  &.cnx-flag-ky {
    background-position: 0 0;
  }

  &.cnx-flag-ad {
    background-position: -32px 0;
  }

  &.cnx-flag-af {
    background-position: 0 -32px;
  }

  &.cnx-flag-ag {
    background-position: -32px -32px;
  }

  &.cnx-flag-ai {
    background-position: -64px 0;
  }

  &.cnx-flag-al {
    background-position: -64px -32px;
  }

  &.cnx-flag-am {
    background-position: 0 -64px;
  }

  &.cnx-flag-an {
    background-position: -32px -64px;
  }

  &.cnx-flag-ao {
    background-position: -64px -64px;
  }

  &.cnx-flag-aq {
    background-position: -96px 0;
  }

  &.cnx-flag-ar {
    background-position: -96px -32px;
  }

  &.cnx-flag-as {
    background-position: -96px -64px;
  }

  &.cnx-flag-at {
    background-position: 0 -96px;
  }

  &.cnx-flag-au {
    background-position: -32px -96px;
  }

  &.cnx-flag-aw {
    background-position: -64px -96px;
  }

  &.cnx-flag-ax {
    background-position: -96px -96px;
  }

  &.cnx-flag-az {
    background-position: -128px 0;
  }

  &.cnx-flag-ba {
    background-position: -128px -32px;
  }

  &.cnx-flag-bb {
    background-position: -128px -64px;
  }

  &.cnx-flag-bd {
    background-position: -128px -96px;
  }

  &.cnx-flag-be {
    background-position: 0 -128px;
  }

  &.cnx-flag-bf {
    background-position: -32px -128px;
  }

  &.cnx-flag-bg {
    background-position: -64px -128px;
  }

  &.cnx-flag-bh {
    background-position: -96px -128px;
  }

  &.cnx-flag-bi {
    background-position: -128px -128px;
  }

  &.cnx-flag-bj {
    background-position: -160px 0;
  }

  &.cnx-flag-bl {
    background-position: -160px -32px;
  }

  &.cnx-flag-bm {
    background-position: -160px -64px;
  }

  &.cnx-flag-bn {
    background-position: -160px -96px;
  }

  &.cnx-flag-bo {
    background-position: -160px -128px;
  }

  &.cnx-flag-br {
    background-position: 0 -160px;
  }

  &.cnx-flag-bs {
    background-position: -32px -160px;
  }

  &.cnx-flag-bt {
    background-position: -64px -160px;
  }

  &.cnx-flag-bw {
    background-position: -96px -160px;
  }

  &.cnx-flag-by {
    background-position: -128px -160px;
  }

  &.cnx-flag-bz {
    background-position: -160px -160px;
  }

  &.cnx-flag-ca {
    background-position: -192px 0;
  }

  &.cnx-flag-cc {
    background-position: -192px -32px;
  }

  &.cnx-flag-cd {
    background-position: -192px -64px;
  }

  &.cnx-flag-cf {
    background-position: -192px -96px;
  }

  &.cnx-flag-cg {
    background-position: -192px -128px;
  }

  &.cnx-flag-ch {
    background-position: -192px -160px;
  }

  &.cnx-flag-ci {
    background-position: 0 -192px;
  }

  &.cnx-flag-ck {
    background-position: -32px -192px;
  }

  &.cnx-flag-cl {
    background-position: -64px -192px;
  }

  &.cnx-flag-cm {
    background-position: -96px -192px;
  }

  &.cnx-flag-cn {
    background-position: -128px -192px;
  }

  &.cnx-flag-co {
    background-position: -160px -192px;
  }

  &.cnx-flag-cr {
    background-position: -192px -192px;
  }

  &.cnx-flag-cu {
    background-position: -224px 0;
  }

  &.cnx-flag-cv {
    background-position: -224px -32px;
  }

  &.cnx-flag-cw {
    background-position: -224px -64px;
  }

  &.cnx-flag-cx {
    background-position: -224px -96px;
  }

  &.cnx-flag-cy {
    background-position: -224px -128px;
  }

  &.cnx-flag-cz {
    background-position: -224px -160px;
  }

  &.cnx-flag-de {
    background-position: -224px -192px;
  }

  &.cnx-flag-dj {
    background-position: 0 -224px;
  }

  &.cnx-flag-dk {
    background-position: -32px -224px;
  }

  &.cnx-flag-dm {
    background-position: -64px -224px;
  }

  &.cnx-flag-do {
    background-position: -96px -224px;
  }

  &.cnx-flag-dz {
    background-position: -128px -224px;
  }

  &.cnx-flag-ec {
    background-position: -160px -224px;
  }

  &.cnx-flag-ee {
    background-position: -192px -224px;
  }

  &.cnx-flag-eg {
    background-position: -224px -224px;
  }

  &.cnx-flag-eh {
    background-position: -256px 0;
  }

  &.cnx-flag-er {
    background-position: -256px -32px;
  }

  &.cnx-flag-es {
    background-position: -256px -64px;
  }

  &.cnx-flag-et {
    background-position: -256px -96px;
  }

  &.cnx-flag-eu {
    background-position: -256px -128px;
  }

  &.cnx-flag-fi {
    background-position: -256px -160px;
  }

  &.cnx-flag-fj {
    background-position: -256px -192px;
  }

  &.cnx-flag-fk {
    background-position: -256px -224px;
  }

  &.cnx-flag-fm {
    background-position: 0 -256px;
  }

  &.cnx-flag-fo {
    background-position: -32px -256px;
  }

  &.cnx-flag-fr {
    background-position: -64px -256px;
  }

  &.cnx-flag-ga {
    background-position: -96px -256px;
  }

  &.cnx-flag-gb {
    background-position: -128px -256px;
  }

  &.cnx-flag-gd {
    background-position: -160px -256px;
  }

  &.cnx-flag-ge {
    background-position: -192px -256px;
  }

  &.cnx-flag-gg {
    background-position: -224px -256px;
  }

  &.cnx-flag-gh {
    background-position: -256px -256px;
  }

  &.cnx-flag-gi {
    background-position: -288px 0;
  }

  &.cnx-flag-gl {
    background-position: -288px -32px;
  }

  &.cnx-flag-gm {
    background-position: -288px -64px;
  }

  &.cnx-flag-gn {
    background-position: -288px -96px;
  }

  &.cnx-flag-gq {
    background-position: -288px -128px;
  }

  &.cnx-flag-gr {
    background-position: -288px -160px;
  }

  &.cnx-flag-gs {
    background-position: -288px -192px;
  }

  &.cnx-flag-gt {
    background-position: -288px -224px;
  }

  &.cnx-flag-gu {
    background-position: -288px -256px;
  }

  &.cnx-flag-gw {
    background-position: 0 -288px;
  }

  &.cnx-flag-gy {
    background-position: -32px -288px;
  }

  &.cnx-flag-hk {
    background-position: -64px -288px;
  }

  &.cnx-flag-hn {
    background-position: -96px -288px;
  }

  &.cnx-flag-hr {
    background-position: -128px -288px;
  }

  &.cnx-flag-ht {
    background-position: -160px -288px;
  }

  &.cnx-flag-hu {
    background-position: -192px -288px;
  }

  &.cnx-flag-ic {
    background-position: -224px -288px;
  }

  &.cnx-flag-id {
    background-position: -256px -288px;
  }

  &.cnx-flag-ie {
    background-position: -288px -288px;
  }

  &.cnx-flag-il {
    background-position: -320px 0;
  }

  &.cnx-flag-im {
    background-position: -320px -32px;
  }

  &.cnx-flag-in {
    background-position: -320px -64px;
  }

  &.cnx-flag-iq {
    background-position: -320px -96px;
  }

  &.cnx-flag-ir {
    background-position: -320px -128px;
  }

  &.cnx-flag-is {
    background-position: -320px -160px;
  }

  &.cnx-flag-it {
    background-position: -320px -192px;
  }

  &.cnx-flag-je {
    background-position: -320px -224px;
  }

  &.cnx-flag-jm {
    background-position: -320px -256px;
  }

  &.cnx-flag-jo {
    background-position: -320px -288px;
  }

  &.cnx-flag-jp {
    background-position: 0 -320px;
  }

  &.cnx-flag-ke {
    background-position: -32px -320px;
  }

  &.cnx-flag-kg {
    background-position: -64px -320px;
  }

  &.cnx-flag-kh {
    background-position: -96px -320px;
  }

  &.cnx-flag-ki {
    background-position: -128px -320px;
  }

  &.cnx-flag-km {
    background-position: -160px -320px;
  }

  &.cnx-flag-kn {
    background-position: -192px -320px;
  }

  &.cnx-flag-kp {
    background-position: -224px -320px;
  }

  &.cnx-flag-kr {
    background-position: -256px -320px;
  }

  &.cnx-flag-kw {
    background-position: -288px -320px;
  }

  &.cnx-flag-ae {
    background-position: -320px -320px;
  }

  &.cnx-flag-kz {
    background-position: -352px 0;
  }

  &.cnx-flag-la {
    background-position: -352px -32px;
  }

  &.cnx-flag-lb {
    background-position: -352px -64px;
  }

  &.cnx-flag-lc {
    background-position: -352px -96px;
  }

  &.cnx-flag-li {
    background-position: -352px -128px;
  }

  &.cnx-flag-lk {
    background-position: -352px -160px;
  }

  &.cnx-flag-lr {
    background-position: -352px -192px;
  }

  &.cnx-flag-ls {
    background-position: -352px -224px;
  }

  &.cnx-flag-lt {
    background-position: -352px -256px;
  }

  &.cnx-flag-lu {
    background-position: -352px -288px;
  }

  &.cnx-flag-lv {
    background-position: -352px -320px;
  }

  &.cnx-flag-ly {
    background-position: 0 -352px;
  }

  &.cnx-flag-ma {
    background-position: -32px -352px;
  }

  &.cnx-flag-mc {
    background-position: -64px -352px;
  }

  &.cnx-flag-md {
    background-position: -96px -352px;
  }

  &.cnx-flag-me {
    background-position: -128px -352px;
  }

  &.cnx-flag-mf {
    background-position: -160px -352px;
  }

  &.cnx-flag-mg {
    background-position: -192px -352px;
  }

  &.cnx-flag-mh {
    background-position: -224px -352px;
  }

  &.cnx-flag-mk {
    background-position: -256px -352px;
  }

  &.cnx-flag-ml {
    background-position: -288px -352px;
  }

  &.cnx-flag-mm {
    background-position: -320px -352px;
  }

  &.cnx-flag-mn {
    background-position: -352px -352px;
  }

  &.cnx-flag-mo {
    background-position: -384px 0;
  }

  &.cnx-flag-mp {
    background-position: -384px -32px;
  }

  &.cnx-flag-mq {
    background-position: -384px -64px;
  }

  &.cnx-flag-mr {
    background-position: -384px -96px;
  }

  &.cnx-flag-ms {
    background-position: -384px -128px;
  }

  &.cnx-flag-mt {
    background-position: -384px -160px;
  }

  &.cnx-flag-mu {
    background-position: -384px -192px;
  }

  &.cnx-flag-mv {
    background-position: -384px -224px;
  }

  &.cnx-flag-mw {
    background-position: -384px -256px;
  }

  &.cnx-flag-mx {
    background-position: -384px -288px;
  }

  &.cnx-flag-my {
    background-position: -384px -320px;
  }

  &.cnx-flag-mz {
    background-position: -384px -352px;
  }

  &.cnx-flag-na {
    background-position: 0 -384px;
  }

  &.cnx-flag-nc {
    background-position: -32px -384px;
  }

  &.cnx-flag-ne {
    background-position: -64px -384px;
  }

  &.cnx-flag-nf {
    background-position: -96px -384px;
  }

  &.cnx-flag-ng {
    background-position: -128px -384px;
  }

  &.cnx-flag-ni {
    background-position: -160px -384px;
  }

  &.cnx-flag-nl {
    background-position: -192px -384px;
  }

  &.cnx-flag-no {
    background-position: -224px -384px;
  }

  &.cnx-flag-np {
    background-position: -256px -384px;
  }

  &.cnx-flag-nr {
    background-position: -288px -384px;
  }

  &.cnx-flag-nu {
    background-position: -320px -384px;
  }

  &.cnx-flag-nz {
    background-position: -352px -384px;
  }

  &.cnx-flag-om {
    background-position: -384px -384px;
  }

  &.cnx-flag-pa {
    background-position: -416px 0;
  }

  &.cnx-flag-pe {
    background-position: -416px -32px;
  }

  &.cnx-flag-pf {
    background-position: -416px -64px;
  }

  &.cnx-flag-pg {
    background-position: -416px -96px;
  }

  &.cnx-flag-ph {
    background-position: -416px -128px;
  }

  &.cnx-flag-pk {
    background-position: -416px -160px;
  }

  &.cnx-flag-pl {
    background-position: -416px -192px;
  }

  &.cnx-flag-pn {
    background-position: -416px -224px;
  }

  &.cnx-flag-pr {
    background-position: -416px -256px;
  }

  &.cnx-flag-ps {
    background-position: -416px -288px;
  }

  &.cnx-flag-pt {
    background-position: -416px -320px;
  }

  &.cnx-flag-pw {
    background-position: -416px -352px;
  }

  &.cnx-flag-py {
    background-position: -416px -384px;
  }

  &.cnx-flag-qa {
    background-position: 0 -416px;
  }

  &.cnx-flag-ro {
    background-position: -32px -416px;
  }

  &.cnx-flag-rs {
    background-position: -64px -416px;
  }

  &.cnx-flag-ru {
    background-position: -96px -416px;
  }

  &.cnx-flag-rw {
    background-position: -128px -416px;
  }

  &.cnx-flag-sa {
    background-position: -160px -416px;
  }

  &.cnx-flag-sb {
    background-position: -192px -416px;
  }

  &.cnx-flag-sc {
    background-position: -224px -416px;
  }

  &.cnx-flag-sd {
    background-position: -256px -416px;
  }

  &.cnx-flag-se {
    background-position: -288px -416px;
  }

  &.cnx-flag-sg {
    background-position: -320px -416px;
  }

  &.cnx-flag-sh {
    background-position: -352px -416px;
  }

  &.cnx-flag-si {
    background-position: -384px -416px;
  }

  &.cnx-flag-sk {
    background-position: -416px -416px;
  }

  &.cnx-flag-sl {
    background-position: -448px 0;
  }

  &.cnx-flag-sm {
    background-position: -448px -32px;
  }

  &.cnx-flag-sn {
    background-position: -448px -64px;
  }

  &.cnx-flag-so {
    background-position: -448px -96px;
  }

  &.cnx-flag-sr {
    background-position: -448px -128px;
  }

  &.cnx-flag-ss {
    background-position: -448px -160px;
  }

  &.cnx-flag-st {
    background-position: -448px -192px;
  }

  &.cnx-flag-sv {
    background-position: -448px -224px;
  }

  &.cnx-flag-sy {
    background-position: -448px -256px;
  }

  &.cnx-flag-sz {
    background-position: -448px -288px;
  }

  &.cnx-flag-tc {
    background-position: -448px -320px;
  }

  &.cnx-flag-td {
    background-position: -448px -352px;
  }

  &.cnx-flag-tf {
    background-position: -448px -384px;
  }

  &.cnx-flag-tg {
    background-position: -448px -416px;
  }

  &.cnx-flag-th {
    background-position: 0 -448px;
  }

  &.cnx-flag-tj {
    background-position: -32px -448px;
  }

  &.cnx-flag-tk {
    background-position: -64px -448px;
  }

  &.cnx-flag-tl {
    background-position: -96px -448px;
  }

  &.cnx-flag-tm {
    background-position: -128px -448px;
  }

  &.cnx-flag-tn {
    background-position: -160px -448px;
  }

  &.cnx-flag-to {
    background-position: -192px -448px;
  }

  &.cnx-flag-tr {
    background-position: -224px -448px;
  }

  &.cnx-flag-tt {
    background-position: -256px -448px;
  }

  &.cnx-flag-tv {
    background-position: -288px -448px;
  }

  &.cnx-flag-tw {
    background-position: -320px -448px;
  }

  &.cnx-flag-tz {
    background-position: -352px -448px;
  }

  &.cnx-flag-ua {
    background-position: -384px -448px;
  }

  &.cnx-flag-ug {
    background-position: -416px -448px;
  }

  &.cnx-flag-us {
    background-position: -448px -448px;
  }

  &.cnx-flag-uy {
    background-position: -480px 0;
  }

  &.cnx-flag-uz {
    background-position: -480px -32px;
  }

  &.cnx-flag-va {
    background-position: -480px -64px;
  }

  &.cnx-flag-vc {
    background-position: -480px -96px;
  }

  &.cnx-flag-ve {
    background-position: -480px -128px;
  }

  &.cnx-flag-vg {
    background-position: -480px -160px;
  }

  &.cnx-flag-vi {
    background-position: -480px -192px;
  }

  &.cnx-flag-vn {
    background-position: -480px -224px;
  }

  &.cnx-flag-vu {
    background-position: -480px -256px;
  }

  &.cnx-flag-wf {
    background-position: -480px -288px;
  }

  &.cnx-flag-ws {
    background-position: -480px -320px;
  }

  &.cnx-flag-ye {
    background-position: -480px -352px;
  }

  &.cnx-flag-yt {
    background-position: -480px -384px;
  }

  &.cnx-flag-za {
    background-position: -480px -416px;
  }

  &.cnx-flag-zm {
    background-position: -480px -448px;
  }

  &.cnx-flag-zw {
    background-position: 0 -480px;
  }
}

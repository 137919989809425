// this file should be overridden in the partner if they have any form of custom footer
// in this format it is specifically for simple partners

.footer {
  margin-top: 25px;
  background: $color-footer-background;
}

.footer-content {
  @include content-container;
}

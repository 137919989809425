$datepicker-padding: 8px !default;
$datepicker-selected-radius: 24px !default;
$datepicker-button-bottom-border: 4px !default;
$color-datepicker-month-background: $color-partner-primary !default;
$color-datepicker-selection: $color-partner-primary;
$color-datepicker-selection-text: contrast-color($color-datepicker-selection);
$color-datepicker-hover: $color-grey-300;
$color-datepicker-close: $color-partner-primary;

.datepicker {
  background: $color-background-panel;
  width: 400px;
  color: $color-black;

  @include media($media-mobile) {
    width: 100%;
  }
}

.datepicker-selected-dates {
  @include outer-container;

  display: none;
  background: $color-datepicker-selection;
  color: $color-datepicker-selection-text;
}

.datepicker-currently-selecting {
  @include outer-container;

  background: $color-datepicker-selection;
  padding: $datepicker-padding;
  color: $color-datepicker-selection-text;
}

.datepicker-selected {
  @include span-columns(6);

  border-bottom: $datepicker-button-bottom-border solid $color-datepicker-selection;
  cursor: pointer;
  padding: ($datepicker-padding * 1.5) 0 (($datepicker-padding * 1.5) - $datepicker-button-bottom-border);
  text-align: center;

  &:hover {
    border-bottom: $datepicker-button-bottom-border solid $color-datepicker-hover;
  }

  &.selected {
    border-bottom: $datepicker-button-bottom-border solid $color-datepicker-hover;
    font-weight: bold;
  }
}

.datepicker-inner {
  @include outer-container;
  @include omega(7n);

  padding: 0 16px 60px;
  width: 100%;
  overflow-y: scroll;
  text-align: center;
  -webkit-overflow-scrolling: touch;

  .datepicker-days {
    @include row;

    span {
      @include span-columns(1.7);

      padding: $datepicker-padding;
    }
  }

  .datepicker-month {
    @include row;

    margin-top: $datepicker-padding;
    border-top: 1px solid $color-grey-300;
    border-bottom: 1px solid $color-grey-300;
    background: $color-datepicker-month-background;
    width: 100%;
    color: contrast-color($color-datepicker-month-background);
    font-weight: bold;

    span {
      @include span-columns(12);

      padding: ($datepicker-padding * 1.5) 0;
    }
  }

  .datepicker-dates {
    @include row;

    span {
      // Manual layout required as otherwise we can't colour in
      // the highlighted elements.
      float: left;
      margin: calc($datepicker-padding / 2) 0;
      padding: $datepicker-padding;
      width: 14.2857143%; // stylelint-disable-line
    }
  }

  %selected-date {
    border: 2px solid $color-datepicker-selection;
    border-radius: $datepicker-selected-radius;
    background: $color-datepicker-selection;
    color: $color-datepicker-selection-text;
  }

  &.pickup {
    .datepicker-pickup-date {
      @extend %selected-date;
    }
  }

  &.dropoff {
    .datepicker-dropoff-date {
      @extend %selected-date;
    }
  }

  .datepicker-non-selectable-date {
    cursor: default;
    color: $color-grey-300;
  }

  .datepicker-selectable-date {
    cursor: pointer;

    &:hover {
      border-radius: $datepicker-selected-radius;
      background: $color-datepicker-hover;
      color: contrast-color($color-datepicker-hover);
    }
  }

  @include media($media-not-mobile) {
    max-height: 500px;
  }

  @include media($media-mobile) {
    max-height: 100%;
  }
}

$color-sidebar-background: $color-partner-primary !default;
$color-sidebar-link-background: $color-sidebar-background !default;
$color-sidebar-link-border: $color-partner-secondary !default;
$color-sidebar-link-active: $color-partner-secondary !default;
$color-sidebar-link-text: contrast-color($color-sidebar-link-background) !default;

.sidebar {
  background: $color-sidebar-background;
  color: $color-sidebar-link-text;
}

.sidebar-selectors {
  border-bottom: 1px solid $color-sidebar-link-border;
  height: $header-height;
  line-height: $header-height - 8px;
}

.sidebar-selector {
  display: inline-block;
  width: 49%;
  text-align: center;
  font-size: 20px;

  .header-link-currency-picker {
    top: 2px;
  }

  @include media($media-mobile) {
    @if $header-height-mobile {
      height: $header-height-mobile;
      line-height: $header-height-mobile - 8px;
    }
  }
}

.sidebar-links-top,
.sidebar-links {
  @include reset-ul;
}

.sidebar-links {
  border-top: 1px solid $color-sidebar-link-border;
  border-bottom: 1px solid $color-sidebar-link-border;
}

.sidebar-link {
  position: relative;
  border-top: 1px solid $color-sidebar-link-border;
  background-color: $color-sidebar-link-background;
  padding: $site-margin;
  font-weight: 300;

  a {
    display: block;
    text-decoration: none;
    color: inherit;

    &:active {
      background-color: $color-sidebar-link-active;
    }

    &:visited {
      color: $color-sidebar-link-text;
    }
  }
}

.sidebar-links-top {
  @include clearfix;

  .sidebar-link-top {
    border-top: 1px solid $color-sidebar-link-border;
    padding: $site-margin;
  }
}

.sidebar-link-icon {
  @include content-with-icon-icon;

  margin-right: 6px;
}

.sidebar-link-text {
  @include content-with-icon-content;

  text-decoration: none;
  color: inherit;
}

%map-button {
  @include action-button;

  padding: $site-margin;
  line-height: 1;
  font-weight: normal;

  i {
    margin-right: 3px;
  }
}

.map-search-open {
  overflow: hidden;
}

.map-search {
  #map {
    .widget-body & {
      height: 300px;
    }

    :not(.widget-body) & {
      @include media($media-not-portable) {
        height: 300px;
      }
    }
  }

  .map-buttons-wrapper {
    display: none;
  }

  .redo-search-button {
    @extend %map-button;

    display: none;
    margin: 10px 0 0 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;

    .widget-body & {
      display: inline-block;
    }

    :not(.widget-body) & {
      @include media($media-not-portable) {
        display: inline-block;
      }
    }
  }

  .reset-map-button {
    @extend %map-button;

    display: none;
    margin: 10px 10px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;

    .widget-body & {
      display: inline-block;
    }

    :not(.widget-body) & {
      @include media($media-not-portable) {
        display: inline-block;
      }
    }
  }

  .close-map-button {
    @extend %map-button;

    margin-bottom: $site-margin * 2;
    font-size: 1.2em;

    .widget-body & {
      display: none;
    }

    :not(.widget-body) & {
      @include media($media-not-portable) {
        display: none;
      }
    }
  }

  .widget-body & {
    margin-bottom: $site-margin;
  }

  :not(.widget-body) & {
    @include media($media-not-portable) {
      margin-bottom: $site-margin;
    }
  }

  @include media($media-portable) {
    .map-search-open & {
      display: block;
      position: fixed;
      z-index: 99;
      inset: 50px 0 0;

      #map {
        height: 100%;
      }
    }
  }
}

.show-map-button-wrapper {
  display: none;

  .map-search-page:not(.widget-body) & {
    display: block;
    margin-bottom: $site-margin;
    text-align: center;

    .show-map-button {
      @extend %map-button;
    }

    @include media($media-not-portable) {
      display: none;
    }
  }
}

$action-button-line-height: 50px !default;
$action-button-font-weight: bold !default;
$highlight-button-on-hover: false !default;

// Basic button with no styling, used for filters
@mixin basic-button($color: $color-grey-300, $hover-color: $color-grey-50) {
  @include shadow-on-hover($shadow-opacity: 0.7);

  border: 1px solid $color;
  border-radius: $site-border-radius;

  &:hover {
    background-color: $hover-color;
  }
}

// basic button with no styling, used as base for other buttons
@mixin button {
  border-radius: $site-border-radius;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: $action-button-line-height;
  font-weight: $action-button-font-weight;
}

// call to action, used for action buttons on search form + search results etc
@mixin action-button(
  $background: $color-call-to-action,
  $color: $color-call-to-action-text,
  $background-hover: $color-call-to-action-hover,
  $color-hover: $color-call-to-action-hover-text,
  $background-pressed: $color-call-to-action-pressed,
  $color-pressed: $color-call-to-action-pressed-text
) {
  @include button;

  border: none 0;
  background-color: $background;
  color: $color;
  user-select: none;

  @if $highlight-button-on-hover {
    &:hover {
      background-color: $background-hover;
      color: $color-hover;
    }
  }

  @if $highlight-button-on-hover {
    &:active {
      background-color: $background-pressed;
      color: $color-pressed;
    }
  }

  &:hover {
    color: $color;
  }
}

// call to action that expands to fill the parent, used for order total on summary screen etc
@mixin action-button-full-width() {
  @include action-button;

  display: block;
  margin: $site-margin 0 0;
  width: 100%;
  text-align: center;
}

// button to match card panel, used for full details and terms buttons in rate card panel
@mixin card-panel-button() {
  @include button;

  margin-top: 8px;
  border: 1px solid $color-grey-300;
  border-radius: $site-border-radius;
  background: $color-info-button-background;
  height: 35px;
  text-transform: uppercase;
  line-height: 35px;
  color: $color-info-button;
}

.car-acriss {
  display: none;
  margin-bottom: calc($site-margin / 2);
  border-bottom: 1px solid $color-grey-300;
  padding: calc($site-margin / 2) $site-margin;
  font-size: 1.1em;
  font-weight: bold;
}

.car-category {
  font-size: 1.1em;
  font-weight: bold;
}

.car-features-basic,
.car-features-cargo,
.car-features-descriptive {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: calc($site-margin / 2);

  /* some trickery to add margin only when any content */
  &:has(*) {
    margin-bottom: calc($site-margin / 2);
  }
}

@include media($media-not-mobile) {
  .car-features-basic,
  .car-features-cargo {
    display: inline-flex;
  }
}

.car-feature {
  white-space: nowrap;

  > .icon-dimensions-3d::before,
  > .icon-weight::before {
    font-size: 1.3em;
  }

  > .icon-dimensions-3d::before {
    font-weight: 600;
  }
}

.car-pickup-location {
  display: flex;
  align-items: center;
  margin: calc($site-margin / 4);
  overflow: hidden;
  white-space: nowrap;
}

.car-pickup-location-icon {
  flex: 0 0 auto;
}

$alert-padding: 15px !default;
$alert-border-radius: $site-border-radius !default;
$alert-link-font-weight: bold !default;

$alert-success-bg: $color-success-bg !default;
$alert-success-text: $color-success-text !default;
$alert-success-border: $color-success-border !default;

$alert-info-bg: $color-info-bg !default;
$alert-info-text: $color-info-text !default;
$alert-info-border: $color-info-border !default;

$alert-warning-bg: $color-warning-bg !default;
$alert-warning-text: $color-warning-text !default;
$alert-warning-border: $color-warning-border !default;

$alert-danger-bg: $color-danger-bg !default;
$alert-danger-text: $color-danger-text !default;
$alert-danger-border: $color-danger-border !default;

.alert {
  display: none;
  margin-bottom: $site-margin * 2;
  border: 1px solid transparent;
  border-radius: $alert-border-radius;
  padding: $alert-padding;

  // Provide class for links that match alerts
  .alert-link {
    font-weight: $alert-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

.alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

// this file should be overridden in the partner if they have any form of custom header
// in this format it is specifically for simple partners

$hide-header-link-carhire: false !default;
$color-header-background: $color-partner-primary !default;
$color-header-color: color-contrast($color-header-background) !default;
$color-header-carhire-link: $color-partner-secondary !default;
$color-header-contact-infos: $color-partner-secondary !default;

$header-vertical-padding: 4px !default; // new default value will be 0.75em, check demo partner
$header-tools-background-hover: mix-with-white($color-partner-primary, 30%);
$header-button-bg: color-contrast($color-header-background, $color-partner-primary, $color-partner-secondary) !default;
$header-button-color: color-contrast($header-button-bg, $color-partner-primary) !default;

.header {
  @include outer-container;

  background: $color-header-background;
  height: $header-height;
  color: $color-header-color;
}

.simple-header-content {
  display: flex;
  justify-content: space-between;
  padding: $header-vertical-padding $site-margin;
  height: 100%;
}

.partner-logo {
  margin-left: $site-margin;
  white-space: nowrap;

  a {
    text-decoration: none;
  }
}

.partner-logo-img {
  @include vertical-center;

  max-width: 100%;
  max-height: 100%;
}

.header-link-carhire {
  padding-left: 20px;
  vertical-align: middle;
  color: $color-header-carhire-link;
  font-size: 1.5rem;
  font-weight: bolder;

  @if $hide-header-link-carhire == true {
    display: none;
  }

  @include media($media-portable) {
    overflow: hidden;
    font-size: 1.3rem;
  }

  @include media($media-mobile) {
    display: none;
  }
}

.header-links-wrapper {
  display: flex;
  align-items: center;
}

.header-links,
.header-selectors {
  display: inline-block;
}

.header-selectors {
  padding: 0 $site-margin-half;
  font-size: 18px;
}

.header-selector {
  display: inline-block;
  vertical-align: middle;
}

.header-link-contact-info {
  display: inline-block;
  white-space: nowrap;
  font-size: 0.9rem;

  a {
    padding: 5px;
    text-decoration: none;
    color: $color-header-contact-infos;
  }

  @include media($media-mobile) {
    display: none;
  }
}

.header-links {
  font-size: 18px;
}

.header-link {
  display: inline-block;
}

.header-link,
.header-link-language-picker,
.header-link-currency-picker {
  position: relative;
  padding: 0 $site-margin-half;
  text-decoration: none;
  color: inherit;

  i {
    padding: 4px;
    vertical-align: middle;
  }
}

.header-link-language-picker,
.header-link-currency-picker {
  display: table;
  position: relative;
  margin: 0 auto;
  padding: 0 calc($site-margin / 2);
  text-decoration: none;
  color: inherit;

  i {
    padding: 4px;
    vertical-align: middle;
  }

  &[data-display-mode='ul']:hover {
    background-color: $header-tools-background-hover;

    .currency-hidden-list {
      display: block;
    }

    .language-picker-selected,
    .currency-picker-selected {
      color: contrast-color($header-tools-background-hover);
    }
  }

  .language-picker-selected,
  .currency-picker-selected {
    display: table-row;
  }

  .language-hidden-select,
  .currency-hidden-select {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 999;
    width: 100%;
    min-width: 50px;
    color: $color-text-main;
  }

  .language-hidden-list,
  .currency-hidden-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    margin: 0;
    box-shadow: 0 0 4px #ccc;
    background: #fff;
    padding: 0;
    width: 140px;
    list-style: none;
  }

  .language-hidden-list-item,
  .currency-hidden-list-item {
    border-bottom: 1px solid #e4e4e4;

    a {
      display: block;
      padding: 0 calc($site-margin / 2);
      text-decoration: none;
      color: #666;
    }

    a:hover {
      background-color: #f4f4f4;
    }
  }
}

.header-link-language-picker {
  .language-picker-flag {
    display: inline-block;
    max-height: 32px;
    vertical-align: middle;
  }
}

.sidebar-toggle {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 $site-margin;
  height: $header-height;
  line-height: $header-height;

  i {
    display: block;
    font-size: 28px;
  }
}

.btn-manage-booking {
  @include button-variant($header-button-bg, $header-button-color);
}

@include media($media-mobile) {
  @if $header-height-mobile {
    .header {
      height: $header-height-mobile;
    }

    .sidebar-toggle {
      height: $header-height-mobile;
      line-height: $header-height-mobile;
    }
  }

  .partner-logo {
    display: block;
    margin: 0 auto;
    max-width: 50%;
    text-align: center;
  }

  .header-links-wrapper {
    display: none;
  }

  .sidebar-toggle {
    display: block;
  }
}

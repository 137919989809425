@mixin shadow-low() {
  // scss-lint:disable ColorVariable
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

@mixin shadow-mid() {
  // scss-lint:disable ColorVariable
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

@mixin shadow-high() {
  // scss-lint:disable ColorVariable
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

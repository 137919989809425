.confirmation-cancel-wrapper {
  .cancellation-confirmation-popout {
    display: none;
  }
}

.cancellation-details-cancel-button {
  @include action-button-full-width;

  text-transform: uppercase;
}

.cancellation-confirmation-popout {
  padding: $site-margin;

  .cancellation-details-cancel-button {
    $cancellation-red: #f00;

    @include set-color($cancellation-red);
  }

  .cancellation-successful,
  .cancellation-failed {
    display: none;
  }

  .cancellation-processing,
  .confirm-cancellation {
    display: none;

    &.cancelling {
      display: block;
    }
  }
}

.lightbox .lightbox-close-button.cancelling {
  display: none;
}

.lightbox-cancellation-popout {
  .lightbox-vertical-center {
    max-width: 500px;
  }

  @include media($media-mobile) {
    .lightbox-title-bar {
      display: none;
    }
  }
}

@import 'mixins/color';
@import 'mixins/resets';
@import 'mixins/shadows';
@import 'mixins/layout';
@import 'mixins/panels';
@import 'mixins/buttons';
@import 'mixins/search';
@import 'mixins/partner';
@import 'mixins/loader';

@mixin arrow($size, $position, $offset, $color) {
  position: absolute;
  z-index: 99;
  border: solid;
  content: '';

  @if $position == top {
    top: -$size;
    right: $offset;
    border-width: 0 $size $size;
    border-color: transparent transparent $color;
  } @else if $position == bottom {
    right: $offset;
    bottom: -$size;
    border-width: $size $size 0;
    border-color: $color transparent transparent;
  }
}

@mixin arrow-top($size, $right, $color) {
  position: absolute;
  top: -$size;
  right: $right;
  z-index: 99;
  border: solid;
  border-width: 0 $size $size;
  border-color: transparent transparent $color;
  content: '';
}

$available-extras-border-radius: 4px !default;
$color-available-extras-border: $color-grey-300 !default;
$color-available-extra-background: $color-background-panel !default;

.manage-extras {
  @include basic-panel;

  margin: 0;

  .manage-extras-header {
    @include basic-panel-header;

    i {
      float: right;
    }

    .manage-extras-subheader {
      @include basic-panel-subheader;
    }
  }
}

.manage-extras-section {
  .manage-extras-collapsible {
    .available-extras {
      display: none;
    }
  }

  &.active {
    .manage-extras-header i {
      transform: rotate(180deg);
    }

    .available-extras {
      display: block;
    }
  }

  @include media($media-mobile) {
    @include span-columns(12);
  }
}

.available-extras {
  @include basic-panel-content;

  margin: 0;
  padding: 0;
  list-style: none;
}

.available-extra {
  border-bottom: 1px solid $color-available-extras-border;
  background: $color-available-extra-background;
  padding: 10px;

  &:last-of-type {
    border-bottom: 0;
  }

  .available-extra-content {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
  }

  .available-extra-header {
    margin: 0 0 8px;
    font-size: 1.1em;
  }

  .available-extra-price {
    font-weight: normal;
  }

  .available-extra-add-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .available-extra-add-button {
    @include action-button;

    display: block;
    margin-left: 10px;
    padding: 0 20px;
    text-align: center;
    white-space: nowrap;
  }

  .available-extra-button-disabled {
    box-shadow: inset 0 0 0 1px $color-grey-300;
    background: $color-info-button-inactive-background;
    cursor: default;
    color: $color-info-button-inactive;
  }
}

.selected-extra {
  border-bottom: 1px solid $color-available-extras-border;
  padding: 10px;

  .selected-extra-content {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
  }

  .selected-extra-remove-button {
    margin-right: 8px;
    text-decoration: none;
    color: $color-warning;
    font-size: 18px;
  }

  .selected-extra-text {
    font-weight: bold;
  }

  .selected-extra-total {
    display: table-cell;
    vertical-align: middle;
  }
}

@charset 'UTF-8';

@font-face {
  font-family: default-icons; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  font-weight: normal;
  font-style: normal;
  src: url('https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.eot?93113442');
  src:
    url('https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.eot?93113442#iefix') format('embedded-opentype'),
    url('https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.woff2?93113442') format('woff2'),
    url('https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.woff?93113442') format('woff'),
    url('https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.ttf?93113442') format('truetype'),
    url('https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.svg?93113442#default-icons') format('svg');
}

@mixin icon-common() {
  text-transform: none;
  line-height: 1em;
  font-family: default-icons; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-style: normal;
  speak: none;

  /* additional CSS declaration generated by fontello but removed
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  */
}

[data-icon]::before {
  @include icon-common;

  line-height: 1;
  content: attr(data-icon);
}

@mixin icon() {
  @include icon-common;

  position: relative;
  top: 1px;
}

[data-cnx-container] {
  [class^='icon-']::before,
  [class*=' icon-']::before,
  %icon::before {
    @include icon;
  }

  .icon-credit-card::before,
  .icon-car::before,
  .icon-truck::before,
  .icon-lock::before,
  .icon-arrows-cw::before,
  .icon-user-circle::before,
  .icon-lightning::before {
    top: 0;
  }
}

@import 'shared.iconCodes';

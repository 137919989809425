$color-autocomplete-results: transparent !default;
$color-autocomplete-icon: $color-input-border !default;
$color-autocomplete-border-color: $color-grey-300 !default;
$color-autocomplete-location-icon: null !default;
$color-autocomplete-clear-icon: $color-text-dark !default;

.autocomplete-box {
  position: relative;

  input {
    @include fill-parent;
  }

  .cnx-loading {
    position: absolute;
    top: 1em;
    right: 3em;
    margin: 0;
    font-size: 8px;
  }
}

.autocomplete-results {
  display: none;
  position: relative;
  margin: 0 0 10px;
  background-color: $color-autocomplete-results;
  padding: 0;
  width: 100%;
  max-height: 300px;
  max-height: 30%;
  overflow: hidden;
  list-style: none;

  &.visible {
    display: block;
  }

  .autocomplete-item {
    clear: both;
    border-bottom: 1px solid $color-autocomplete-border-color;
    cursor: pointer;
    padding: 3px 0;
    line-height: 30px;

    &:last-child {
      border-bottom: 0;
    }

    .location-icons {
      @include content-with-icon-icon;
    }

    .location-icon {
      padding: 0 0.5em;

      @if $color-autocomplete-location-icon {
        color: $color-autocomplete-location-icon;
      }
    }

    .location-flag {
      display: inline-block;
    }

    .location-label-wrapper {
      @include content-with-icon-content;

      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 8px;
      line-height: 18px;

      @include media($media-not-portable) {
        padding-top: 7px;
        padding-bottom: 7px;
        line-height: 16px;
      }
    }

    .location-label {
      margin-right: 10px;
    }

    .location-country {
      white-space: nowrap;
      color: #999;
    }
  }

  .autocomplete-item-highlighted {
    background: #eee;
  }

  @include media($media-not-mobile) {
    margin-bottom: 0;
  }
}

.autocomplete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid $color-input-border;
  border-radius: $input-border-radius;
  background-color: $color-white;
  cursor: pointer;
  padding: 0 8px;
  width: 100%;
  height: 100%;
  line-height: $default-input-height;

  .autocomplete-overlay-label {
    float: left;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    i {
      padding-right: 8px;
      color: $color-autocomplete-icon;
    }
  }

  .clear-icon {
    float: right;
    color: $color-autocomplete-clear-icon;
  }
}

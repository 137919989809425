#{$all-text-inputs},
select,
.fake-input {
  @extend %form-element;
}

input::placeholder {
  color: $color-input-placeholder;
}

select:invalid {
  @extend %invalid-select;
}

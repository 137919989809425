$color-header-icon: $color-white !default;
$color-footer: $color-grey-300 !default;
$color-link-footer: $color-text-dark !default;
$background-overlay-gradient-top-mobile: rgb(0 0 0 / 10%) !default;
$background-overlay-gradient-bottom-mobile: rgb(50 50 50 / 30%) !default;
$background-overlay-gradient-top: rgb(0 0 0 / 50%) !default;
$background-overlay-gradient-bottom: rgb(50 50 50 / 30%) !default;
$background-image: url('https://static.carhire-solutions.com/images/wlp/other/homepage-bg-legacy.jpg') !default;
$background-overlay-gradient: linear-gradient(to bottom, $background-overlay-gradient-top 20%, $background-overlay-gradient-bottom 50%) !default;
$background-overlay-gradient-mobile: linear-gradient(to bottom, $background-overlay-gradient-top-mobile 20%, $background-overlay-gradient-bottom-mobile 50%) !default;
$background-position-home-desktop: center bottom !default;
$background-position-home-mobile: right bottom !default;

html {
  margin: 0;
  height: 100%;
  height: 100vh;
}

body {
  position: relative;
  margin: 0;
  background-color: $color-background-main;
  min-width: 320px; // width of the iphone 5 (anything less than this will just destroy the layout
  height: 100%;
  color: $color-text-main;

  @include media($media-portable) {
    font: $font-main-mobile;
  }

  @include media($media-not-portable) {
    font: $font-main-desktop;
  }
}

.widget-body {
  overflow: hidden;
}

.widget-root {
  overflow: hidden;

  .view-root {
    margin-bottom: 20px;
  }
}

.fixed-bg {
  position: fixed;
  top: 0;
  z-index: -1;
  background-attachment: fixed;
  background-image: $background-overlay-gradient, $background-image;
  background-position: $background-position-home-desktop;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 110%;

  @include media($media-mobile) {
    background-image: $background-overlay-gradient-mobile, $background-image;
    background-position: $background-position-home-mobile;
  }
}

.view-root {
  @include fill-parent;
}

.view-container {
  @include content-container;

  background: $color-background-view;
  padding: 1px $default-container-padding $default-container-padding;
}

[data-lightbox='lightbox'],
[data-toggle='toggle'] {
  // This is to make sure click events are triggered on iOS
  cursor: pointer;
  user-select: none;
}

.content-panel {
  @include basic-panel;
}

.content-panel-header {
  @include basic-panel-header;

  .content-panel-header-text {
    float: none;
    overflow: hidden;
  }

  .content-panel-header-icon {
    float: right;
  }
}

.content-panel-subheader {
  display: block;
  margin: $site-margin 0 0;
  color: $color-text-dark-secondary;
  font-size: $font-size-desktop;
  font-weight: bold;
}

.content-panel-content {
  border-top: 1px solid $color-grey-300;
  border-bottom-left-radius: $site-border-radius;
  border-bottom-right-radius: $site-border-radius;
  background: $color-background-panel;
  padding: 0 $site-margin $site-margin;
}

.search-result-wrapper {
  margin-bottom: 12px;
}

.search-result {
  @include row;
  @include basic-panel;
}

.feature-selected {
  display: none;
}

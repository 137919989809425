.is-mobile {
  position: absolute;
  top: -999em;
  left: -999em;
}

@include media($media-mobile) {
  .is-mobile::before {
    content: 'mobile';
  }
}

$car-image-height: 120px !default;
$car-more-details-alignment: center !default;

.car-image-wrapper {
  .more-details-wrapper,
  .pickup-location-wrapper {
    padding-bottom: $site-margin;
    text-align: $car-more-details-alignment;
  }

  .more-details-wrapper + .pickup-location-wrapper {
    margin-top: -$site-margin;
  }

  .car-image img {
    vertical-align: unset;
  }

  // Image full width
  @include media($media-mobile) {
    margin-left: $car-panel-standard-margin;

    img {
      max-width: $car-image-width;
    }
  }

  // Center image vertically
  @include media($media-not-mobile) {
    @include span-columns(3.5);

    padding-left: $car-panel-standard-margin;

    .car-image {
      display: inline-block;
      position: relative;
      width: 100%;
      height: $car-image-height;
      overflow: hidden;
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 100%;
    }
  }
}

@mixin search-results-toggle($height) {
  float: left;
  margin: 0;
  cursor: pointer;
  padding: calc($site-margin / 2) $site-margin;
  height: $height;
  line-height: $height - $site-margin;
}

@mixin search-results-active-toggle {
  background: $color-info-button-background;
  color: $color-info-button;
  font-weight: bold;
}

@mixin search-results-inactive-toggle {
  background: $color-info-button-inactive-background;
  color: $color-info-button-inactive;
  font-weight: normal;
}

.search-results-info {
  $row-size: 36px;
  $mobile-row-size: 24px;
  @include row;

  display: block;
  position: relative;
  margin-bottom: $site-margin;
  line-height: $row-size;
  font-size: 1.2em;
  user-select: none;

  .popular-search-results {
    display: inline-block;
  }

  .all-search-results {
    display: none;
  }

  .location-search-results {
    display: none;
  }

  .search-results-info-title {
    font-weight: bold;
  }

  .results-type {
    @include shadow-on-active;
    @include basic-button;

    position: relative;
    float: right;
    margin: 0;
    cursor: pointer;

    .most-popular,
    .filtered {
      @include search-results-toggle($height: $row-size);
    }

    @include media($media-mobile) {
      display: none;
    }
  }

  .reset-removes-filters {
    $border-color: $color-grey-300;
    @include basic-panel-border($color: $border-color);

    display: none;
    position: absolute;
    right: 0;
    bottom: 111%;
    border-radius: $site-border-radius;
    background: $color-white;
    padding: calc($site-margin / 2);
    width: 255px;
    text-align: center;
    line-height: normal;
    font-size: 14px;

    &::before {
      @include arrow($size: 5px, $position: bottom, $offset: 5%, $color: darken($border-color, 5%));
    }
  }

  .filtered {
    @include search-results-inactive-toggle;
  }

  .most-popular {
    @include search-results-active-toggle;

    border-right: 1px solid $color-grey-300;
    border-top-left-radius: $site-border-radius;
    border-bottom-left-radius: $site-border-radius;
  }

  .popular-search-results,
  .location-search-results,
  .all-search-results {
    opacity: 1;
  }

  &:active {
    .popular-search-results,
    .location-search-results,
    .all-search-results {
      opacity: 0.6;
    }
  }

  &.show-all-search-results {
    .location-search-results,
    .popular-search-results {
      display: none;
    }

    .all-search-results {
      display: inline-block;
    }

    .filtered {
      @include search-results-active-toggle;

      border-top-right-radius: $site-border-radius;
      border-bottom-right-radius: $site-border-radius;
    }

    .most-popular {
      @include search-results-inactive-toggle;
    }

    &.has-active-filters .results-type:hover .reset-removes-filters {
      display: block;
    }
  }

  &.show-location-results {
    .all-search-results,
    .popular-search-results {
      display: none;
    }

    .location-search-results {
      display: inline-block;
    }

    .results-type {
      display: none;
    }
  }

  @include media($media-mobile) {
    text-align: center;
    line-height: $mobile-row-size;
    font-size: 0.8em;
  }
}

.search-results-info-toggle {
  margin: 0 auto;
  width: 90%;

  @mixin toggle() {
    float: left;
    cursor: pointer;
    padding: 8px 0;
    width: 50%;
    text-align: center;
  }

  @mixin search-results-mobile-inactive-toggle() {
    @include search-results-inactive-toggle;

    border: 1px solid $color-info-button-inactive;
  }

  @mixin search-results-mobile-active-toggle() {
    @include search-results-active-toggle;

    border: 1px solid $color-info-button;
  }

  .search-results-info-toggle-header {
    margin: calc($site-margin / 2) 0;
    padding: $site-margin 0;
    color: $color-info-header;
  }

  .results-type-toggle {
    @include shadow-on-hover;
    @include shadow-on-active($shadow-size: calc($site-margin / 2));

    display: inline-block;

    // border: solid 1px $color-grey-300;
    border-radius: $site-border-radius;
    width: 100%;
  }

  .most-popular,
  .filtered {
    @include toggle;
  }

  .most-popular {
    @include search-results-mobile-active-toggle;

    border-top-left-radius: $site-border-radius;
    border-bottom-left-radius: $site-border-radius;
  }

  .filtered {
    @include search-results-mobile-inactive-toggle;

    border-top-right-radius: $site-border-radius;
    border-bottom-right-radius: $site-border-radius;
  }

  &.show-all-search-results {
    .most-popular {
      @include search-results-mobile-inactive-toggle;
    }

    .filtered {
      @include search-results-mobile-active-toggle;

      border-top-right-radius: $site-border-radius;
      border-bottom-right-radius: $site-border-radius;
    }
  }
}

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css'); // stylelint-disable-line

.car-name-wrapper {
  margin: auto 0;
}

.car-img-wrapper {
  @include box-shadow($modal-content-box-shadow-xs);

  align-self: center;
  margin-right: $modal-dialog-margin;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}

.car-name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.price-wrapper {
  align-self: center;
}

.car-price {
  margin-right: $modal-dialog-margin;
}

.car-img {
  border-radius: 3px;
  max-height: 65px;
}

.book-button {
  margin-left: 14px;
  padding: 10px 14px;
}

.button-wrapper {
  align-self: center;
}

.loading {
  background: url('/assets/common/images/loader.gif') no-repeat center;
}

// Inclusions tab

.include-list {
  margin-bottom: 0;
  padding-left: 5px;
  list-style: none;

  li {
    position: relative;
    margin-bottom: 5px;
  }

  .icon-check-solid {
    color: #3fde45;
  }

  .icon-warning {
    color: #f8ba10;
  }
}

$border-color: #dee2e6;

.rental-info-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $border-color;
  border-radius: 4px;

  .features {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $border-color;
    background-color: #f0f0f0;
    padding: 5px 10px;
    white-space: nowrap;

    @include media-breakpoint-up(sm) {
      border-right: 1px solid $border-color;
    }
  }

  .supplier {
    align-items: center;
    justify-content: center;
    padding: 10px 5px;

    .supplier-name {
      padding: 0 10px;

      @include media-breakpoint-up(sm) {
        max-width: 130px;
      }
    }
  }
}

.features .car-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    margin: 0 6px;
    text-align: center;
    color: #7c7c7c;

    [class*='icon-'] {
      font-size: 120%;

      @include media-breakpoint-up(
        xxxl,
        (
          xxxl: 1700px,
        )
      ) {
        font-size: 150%;
      }
    }

    span {
      display: block;
      margin-top: 3px;
    }

    @include media-breakpoint-up(
      xxxl,
      (
        xxxl: 1700px,
      )
    ) {
      margin: 0 8px;
    }
  }
}

.vendor-brand {
  --cnx-supplier-logo-height: 37px;

  display: block;
  float: right;
  margin: 0 0 0 5px;
  padding: 0;
  max-width: 100%;

  .vendor-img {
    display: block;
    position: relative;
    width: 100%;
    height: var(--cnx-supplier-logo-height);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

// Station Tab

.opening-hours {
  tr {
    vertical-align: top;
  }

  th {
    min-width: 90px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.handover-day {
  outline: 2px dotted #02bceb;
}

.read-more-wrapper {
  color: #000;
  line-clamp: 1; // stylelint-disable-line

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  label {
    margin-bottom: 0.5rem;
    color: #02bceb;
  }

  .read-more-content {
    display: -webkit-box; // stylelint-disable
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  input:focus ~ label {
    outline: -webkit-focus-ring-color auto 5px;
  }

  input:checked + .read-more-content {
    -webkit-line-clamp: unset;
  }

  input:checked ~ label {
    display: none;
  }
}

// Protection tab

$insurance-background: #ffecac;

.insurance-box {
  background: $insurance-background;
  user-select: none;
}

.btn-link {
  font-weight: 400;
  color: #02bceb;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #004d70;
  }
}

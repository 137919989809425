.location-map-wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  // stylelint-disable-next-line
  #locationMap {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }

  .map-wrapper {
    flex: 1 1 auto;
  }

  .modal-body {
    flex: 0 0 auto;
    max-height: 350px;
    overflow: auto;
  }
}

.lightbox.location-map {
  z-index: 1000;
  background: none;
}

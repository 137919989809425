.order-total-wrapper {
  @include span-columns(5);

  &.sticky-scroll-active,
  &.sticky-scroll-proxy {
    margin-right: 0;
  }

  .content-panel:last-child {
    margin-bottom: 0;
  }

  @include media($media-portable) {
    @include span-columns(12);
  }
}

.order-total-charges-wrapper {
  @include clearfix;

  border-top: 1px solid $color-grey-300;
  background: $color-background-panel;
}

.order-total-charge,
.order-total-charge-included,
.order-total-price-rate,
.order-total-price-now,
.order-total-price-total,
.order-total-price-later {
  @include row;

  padding: $site-margin-half $site-margin;

  .charge-description,
  .price-description {
    float: none;
    overflow: hidden;
  }

  .charge-price,
  .price-amount {
    float: right;
    text-align: right;
  }
}

.order-total-charge-included-description {
  padding: 0 $site-margin 6px;
  font-style: italic;
}

.order-total-price-rate,
.order-total-price-total,
.order-total-price-now {
  .price-description,
  .price-amount {
    font-weight: bold;
  }
}

.order-total-charge,
.order-total-price-total {
  border-top: 1px solid $color-grey-300;
}

.order-total-charge-included {
  padding: 3px 0;
  font-style: italic;
}

.order-total-price-now {
  $pastel-blue: mix-with-white($color-partner-primary, 70%);
  @include set-color($pastel-blue);

  border-top: 1px solid $pastel-blue;
}

.order-total-price-now,
.order-total-price-later,
.order-total-price-total {
  position: relative;
  padding: $site-margin;
  font-size: 1.1em;

  i {
    position: absolute;
    left: $site-margin;
    transition: transform 0.3s $ease-out-cubic;
  }

  .price-description {
    padding-left: $site-margin-double;
  }
}

.order-total-price-later {
  border-top: 1px solid $color-grey-300;
  color: $color-panel-header;

  .charge-price,
  .charge-description {
    padding-top: 10px;

    // font-weight: bold;
    font-size: 0.9em;
    font-style: italic;
  }
}

.order-total-book-button {
  @include action-button;

  display: block;
  float: none;
  margin: 0;
  overflow: hidden;
}

.confirm-marketing {
  float: left;
  padding-right: $site-margin-double;
  line-height: 50px;
  color: mix-with-black($color-positive, 70);
  font-size: 1.2em;
  font-style: italic;

  @include media($media-portable) {
    float: none;
    text-align: center;
  }
}

.confirm-wrapper {
  @include clearfix;

  padding: 0 $site-margin $site-margin;
}

.order-total-reassurance {
  border: 1px solid $color-positive;
  background: mix-with-white($color-positive, 80);
  padding: $site-margin-half $site-margin;
  text-align: center;
  color: $color-positive;
  font-size: 1.1em;
  font-weight: bold;

  i {
    padding-right: 5px;
  }

  .no-payment-required & {
    display: none;
  }
}

.pay-now-breakdown-open .pay-now-breakdown,
.pay-later-breakdown-open .pay-later-breakdown {
  display: block;
}

.pay-now-breakdown-open .order-total-price-now,
.pay-later-breakdown-open .order-total-price-later {
  i {
    transform: rotate(-0.5turn);
  }
}

.pay-now-breakdown,
.pay-later-breakdown {
  display: none;
}

.pay-later-terms,
.pay-later-exchange {
  text-align: center;
  font-style: italic;
}

.pay-later-exchange-rate {
  font-weight: bold;
}

.comodo-secure {
  display: table-row;
  border-top: 1px solid $color-grey-300;
  background: $color-background-panel;
  padding: $site-margin;

  img {
    display: table-cell;
    padding: $site-margin;
    height: 83px;
    vertical-align: middle;
  }

  .content-panel-subheader {
    display: table-cell;
    padding-right: $site-margin;
    width: 100%;
    vertical-align: middle;
  }
}

.order-total-contact-detail {
  display: block;
  padding-top: $site-margin-half;

  i {
    margin: 0 4px;
  }

  a {
    color: $color-partner-primary;
  }
}

.order-total-contact-language-info {
  padding: 10px 0;
}

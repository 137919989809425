$color-input-background: $color-white !default;
$color-input-border: $color-grey-400 !default;
$color-input-placeholder: $color-grey-500 !default;

// The following look incorrect for time picker if input icons are displayed on the left
$color-input-border-focus: null !default;
$color-input-border-hover: null !default;
$color-input-border-active: null !default;

%form-element {
  outline-color: $color-info-header;
  border: 1px solid $color-input-border;
  border-radius: $input-border-radius;
  background-color: $color-input-background;
  padding: 0 0 0 0.5em;
  height: $default-input-height;
  line-height: $default-input-height;
  font-size: 1em;
  appearance: none;

  @if $color-input-border-focus {
    &:focus {
      border-color: $color-input-border-focus;
    }
  }

  @if $color-input-border-hover {
    &:hover {
      border-color: $color-input-border-hover;
    }
  }

  @if $color-input-border-active {
    &:active {
      border-color: $color-input-border-active;
    }
  }
}

%invalid-select {
  background: $color-input-background;
  color: $color-input-placeholder;

  option {
    color: $color-text-main;
  }
}

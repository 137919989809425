// generated by fontello
/* stylelint-disable */
[data-cnx-container] {
  .icon-credit-card::before {
    content: '\e800';
  } /* '' */
  .icon-aircon::before {
    content: '\e801';
  } /* '' */
  .icon-aircon-solid::before {
    content: '\e802';
  } /* '' */
  .icon-airplane::before {
    content: '\e803';
  } /* '' */
  .icon-airplane-solid::before {
    content: '\e804';
  } /* '' */
  .icon-bags::before {
    content: '\e805';
  } /* '' */
  .icon-bags-solid::before {
    content: '\e806';
  } /* '' */
  .icon-bulletpoint::before {
    content: '\e807';
  } /* '' */
  .icon-bulletpoint-small::before {
    content: '\e808';
  } /* '' */
  .icon-calendar::before {
    content: '\e809';
  } /* '' */
  .icon-check::before {
    content: '\e80a';
  } /* '' */
  .icon-check-solid::before {
    content: '\e80b';
  } /* '' */
  .icon-chevron-down::before {
    content: '\e80c';
  } /* '' */
  .icon-chevron-left::before {
    content: '\e80d';
  } /* '' */
  .icon-chevron-right::before {
    content: '\e80e';
  } /* '' */
  .icon-chevron-up::before {
    content: '\e80f';
  } /* '' */
  .icon-clock::before {
    content: '\e810';
  } /* '' */
  .icon-clock-solid::before {
    content: '\e811';
  } /* '' */
  .icon-cross::before {
    content: '\e812';
  } /* '' */
  .icon-doors::before {
    content: '\e813';
  } /* '' */
  .icon-doors-solid::before {
    content: '\e814';
  } /* '' */
  .icon-downtown-solid::before {
    content: '\e815';
  } /* '' */
  .icon-transmission-solid::before {
    content: '\e816';
  } /* '' */
  .icon-transmission::before {
    content: '\e817';
  } /* '' */
  .icon-star-solid::before {
    content: '\e818';
  } /* '' */
  .icon-star::before {
    content: '\e819';
  } /* '' */
  .icon-railway-solid::before {
    content: '\e81a';
  } /* '' */
  .icon-railway::before {
    content: '\e81b';
  } /* '' */
  .icon-phone::before {
    content: '\e81c';
  } /* '' */
  .icon-passengers-solid::before {
    content: '\e81d';
  } /* '' */
  .icon-passengers::before {
    content: '\e81e';
  } /* '' */
  .icon-minus-boxed::before {
    content: '\e81f';
  } /* '' */
  .icon-minus::before {
    content: '\e820';
  } /* '' */
  .icon-location-solid::before {
    content: '\e821';
  } /* '' */
  .icon-location::before {
    content: '\e822';
  } /* '' */
  .icon-info-solid::before {
    content: '\e823';
  } /* '' */
  .icon-info::before {
    content: '\e824';
  } /* '' */
  .icon-fuel-solid::before {
    content: '\e825';
  } /* '' */
  .icon-fuel::before {
    content: '\e826';
  } /* '' */
  .icon-email-solid::before {
    content: '\e827';
  } /* '' */
  .icon-email::before {
    content: '\e828';
  } /* '' */
  .icon-downtown::before {
    content: '\e829';
  } /* '' */
  .icon-menu::before {
    content: '\e82a';
  } /* '' */
  .icon-square::before {
    content: '\e82b';
  } /* '' */
  .icon-check-square::before {
    content: '\e82c';
  } /* '' */
  .icon-lock::before {
    content: '\e82d';
  } /* '' */
  .icon-warning::before {
    content: '\e82e';
  } /* '' */
  .icon-thumbs-up::before {
    content: '\e82f';
  } /* '' */
  .icon-check-circle::before {
    content: '\e830';
  } /* '' */
  .icon-logout::before {
    content: '\e831';
  } /* '' */
  .icon-status-confirmed::before {
    content: '\e832';
  } /* '' */
  .icon-status-cancelled::before {
    content: '\e833';
  } /* '' */
  .icon-status-unknown::before {
    content: '\e834';
  } /* '' */
  .icon-status-pending::before {
    content: '\e835';
  } /* '' */
  .icon-up-dir::before {
    content: '\e836';
  } /* '' */
  .icon-arrows-cw::before {
    content: '\e837';
  } /* '' */
  .icon-ccw::before {
    content: '\e838';
  } /* '' */
  .icon-lightning::before {
    content: '\e839';
  } /* '' */
  .icon-dimensions-3d::before {
    content: '\e83a';
  } /* '' */
  .icon-weight::before {
    content: '\e83b';
  } /* '' */
  .icon-truck::before {
    content: '\e83c';
  } /* '' */
  .icon-driving-license::before {
    content: '\e841';
  } /* '' */
  .icon-car::before {
    content: '\f1b9';
  } /* '' */
  .icon-bus::before {
    content: '\f207';
  } /* '' */
  .icon-user-circle::before {
    content: '\f2be';
  } /* '' */
}
/* stylelint-enable */

// $color-partner-... are not bootstrap variables but rather _partner-colors.scss
// but order of imports might matter (especially as those are !default)
// so just bring it here as it was in the original _bootstrap.scss
$color-partner-primary: #004d70 !default;
$color-partner-secondary: #7199a6 !default;

$primary: $color-partner-primary !default;
$min-contrast-ratio: 3 !default;

$h1-font-size: 2em !default;
$h2-font-size: 1.5em !default;
$h3-font-size: 1.17em !default;
$h4-font-size: 1em !default;
$headings-font-weight: 600 !default;

@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../../node_modules/bootstrap/scss/maps';
@import '../../../../node_modules/bootstrap/scss/mixins';

$car-title-text-size-emphasis: 0.4;
$car-title-text-size: $car-header-line-height * (1 + $car-title-text-size-emphasis);

.car-header-text {
  margin-top: 0;
  margin-bottom: 0;
  padding: $car-desktop-inner-top-padding - ($car-header-line-height * $car-title-text-size-emphasis) 0 0 $car-panel-standard-margin;
  line-height: $car-title-text-size;
  font-size: $car-title-text-size;
  font-weight: bold;

  .car-header & {
    float: none;
    overflow: hidden;
  }

  .car-header-subtext {
    white-space: nowrap;
  }

  small {
    color: $color-grey-600;
    font-size: 0.8em;
  }
}

.menu-options {
  @include outer-container;

  position: relative;
  padding: 18px;
  height: 100%;
  color: $color-white;
}

%settings-panel {
  margin-left: 10px;
  padding: 8px;
}

.language-caption {
  @extend %settings-panel;

  margin-top: 10px;
  color: $color-white;
}

.language {
  @extend %settings-panel;

  label {
    color: $color-white;
  }

  .select-language {
    margin: 0.5em 0;
    width: 100%;

    select {
      display: block;
      width: 100%;
    }
  }
}

.change-settings {
  @extend %settings-panel;

  color: $color-white;
}

.submit-new-settings {
  @include action-button;

  display: block;
  text-align: center;
  text-decoration: none;
}

$color-protection-panel: $color-positive;
$color-car-marketing-item-icon: $color-package-basic !default;

.car-insurance {
  @include inset-panel($panel-color: $color-package-basic);

  display: none;
  margin-bottom: calc($site-margin / 2);
  padding: calc($site-margin / 2) $site-margin;

  .insurance-header {
    color: $color-package-basic;
    font-size: 1.1em;
    font-weight: bold;
  }

  .car-marketing-item-icon {
    color: $color-car-marketing-item-icon;
  }

  &.has-cdw,
  &.has-tp {
    display: block;
  }

  @include media($media-mobile) {
    padding-bottom: $site-margin;
  }
}

.inclusive.car-insurance {
  @include inset-panel($panel-color: $color-package-inclusive);

  .insurance-header,
  .car-marketing-item-icon {
    color: $color-package-inclusive;
  }
}

$image-path: 'https://static.carhire-solutions.com/images/wlp' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';

@mixin svg-background-image($name, $size: false) {
  background-image: url(#{$image-path}/#{$name}.svg);

  @if $size {
    background-size: $size;
  }

  .no-svg & {
    background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});
  }
}

%svg-logo {
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin partner-logo($name) {
  .#{$name}-logo {
    @extend %svg-logo;
    @include svg-background-image('suppliers/#{$name}', contain);
  }
}

@include partner-logo('alamo');
@include partner-logo('avis');
@include partner-logo('budget');
@include partner-logo('enterprise');
@include partner-logo('europcar');
@include partner-logo('hertz');
@include partner-logo('national');
@include partner-logo('sixt');
@include partner-logo('dollar');
@include partner-logo('thrifty');

.lightbox-price-jump {
  .lightbox-inner {
    background: #f5f5f5;
  }

  .lightbox-content {
    @include media($media-mobile) {
      padding-top: 0;
    }
  }

  .price-jump-car-info {
    background: #fff;
    padding: $site-margin 0;

    .price-jump-row {
      clear: both;
      padding-top: 12px;
    }

    .car-header-text {
      display: inline-block;
      margin: 0;
      padding: 0;
      vertical-align: middle;
    }

    .vendor-branding {
      display: inline-block;
      float: none;
      margin: 0 0 0 $site-margin;
      padding: 0;

      @include media($media-mobile) {
        display: block;
        margin-top: calc($site-margin / 2);
        max-width: 100%;
      }
    }

    .vendor-image img {
      vertical-align: middle;

      @include media($media-not-mobile) {
        height: 20px;
      }
    }

    .car-image {
      display: inline-block;
      vertical-align: middle;
    }

    .car-image img {
      width: 150px;
    }

    .more-details-wrapper {
      display: inline-block;
      margin-left: $site-margin;
      vertical-align: middle;
    }
  }

  .confirmation-dialog {
    border-top: 1px solid $color-grey-300;
    background: inherit;
    text-align: center;

    h2 {
      margin-top: 0;
    }

    .price-jump-prices {
      margin: $site-margin-double 0;
      line-height: 200%;
    }

    .new-price {
      font-size: 160%;
    }
  }

  .lightbox-close-button,
  .lightbox-title-bar {
    display: none;
  }
}

@mixin shadow-on-active($speed: 0.5s, $shadow-offset: calc($site-margin / 4), $shadow-size: $site-margin) {
  $shadow-color: rgb(0 0 0 / 30%);

  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity $speed ease-in-out;
    opacity: 0;
    z-index: -1;
    border-radius: $site-border-radius;
    box-shadow: 0 $shadow-offset $shadow-size $shadow-color;
    width: 100%;
    height: 100%;
    content: '';
  }

  &:active::after {
    transition: 0s;
    opacity: 1;
  }
}

@mixin shadow-on-hover($speed: 0.3s, $shadow-offset: 2px, $shadow-size: 4px, $shadow-opacity: 1) {
  $shadow-color: rgb(0 0 0 / 25%);

  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity $speed ease-in-out;
    opacity: 0;
    z-index: -1;
    border-radius: $site-border-radius;
    box-shadow: 0 $shadow-offset $shadow-size 0 $shadow-color;
    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover::before {
    transition: 0s;
    opacity: $shadow-opacity;
  }
}

@mixin scale-on-hover($speed: 0.15s, $scale: 1.02) {
  transition: transform $speed ease-in-out;

  &:hover {
    transform: scale($scale, $scale);
  }
}

@keyframes highlight {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.4;
  }

  100% {
    opacity: 0;
  }
}

@mixin highlight-on-render($color: $color-focus) {
  $speed: 0.5s;
  $shadow-size: $site-margin;

  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity $speed ease-in-out;
    opacity: 0;
    z-index: -1;
    border-radius: $site-border-radius;
    box-shadow: 0 0 $shadow-size $color;
    width: 100%;
    height: 100%;
    animation-name: highlight;
    animation-duration: 4s;
    content: '';
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }

  70% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@mixin fade-in-on-render() {
  animation-name: fade-in;
  animation-duration: 0.3s;
}

$car-marketing-item-height: 25px;
$icon-height: $car-marketing-item-height - 12px;

.car-insurance,
.car-marketing {
  $border-width: 2px;

  @include clearfix;

  padding-left: $site-margin;
  min-height: $car-marketing-item-height + ($border-width * 2);
}

.car-marketing {
  position: relative;
  margin-top: $site-margin;
}

.car-marketing-single-item-line,
.car-marketing-two-line-item {
  float: left;
  padding-top: calc($site-margin / 2);
  padding-right: $site-margin;
  width: 100%;
  min-height: 3rem;

  .car-marketing-item {
    width: 100%;
  }

  .car-marketing-item-caption {
    margin-bottom: 2px;
    font-size: 0.9em;
  }
}

.car-marketing-two-line-item {
  width: 50%;

  &:last-of-type {
    padding-right: 0;
  }
}

.car-marketing-item {
  $icon-height: $car-marketing-item-height - 12px;

  float: left;
  width: 50%;
  font-size: 14px;

  .car-marketing-item-content {
    @include content-with-icon-content;

    padding-top: 5px;
    padding-bottom: 5px;
    line-height: $car-marketing-item-height - 10;
  }

  .car-marketing-item-icon-text,
  .car-marketing-item-icon {
    @include content-with-icon-icon;

    width: $car-marketing-item-height;
    text-align: center;
    line-height: $car-marketing-item-height;
    font-size: $icon-height;
  }

  .car-marketing-item-icon-text {
    @include content-with-icon-icon;

    line-height: $car-marketing-item-height;
    font-size: $car-marketing-item-height;
    font-weight: bold;
  }

  // Temporarily use images to backfill missing icons
  img {
    margin: 6px auto;
    width: $icon-height - 6px;
    height: $icon-height - 6px;
  }
}

.car-marketing-line {
  display: flex;
  column-gap: $site-margin;
  margin-top: calc(3 * $site-margin / 2);
  margin-inline: $site-margin;

  > * {
    width: 100%;
  }
}

.car-marketing-caption {
  margin-bottom: 2px;
  font-size: 0.9em;
}

.car-marketing-content {
  display: flex;
  align-items: center;
  font-size: 14px;

  .car-marketing-item-icon {
    flex: none;
    width: $car-marketing-item-height;
    text-align: center;
    font-size: $icon-height;
  }

  .car-marketing-item-content {
    margin-block: 5px;
  }
}

.car-marketing-terms-and-conditions-line {
  margin-top: calc($site-margin / 2);

  > * {
    margin-top: $site-margin;
  }

  .car-marketing-terms-and-conditions {
    align-self: flex-end;
    margin-top: 0;
    margin-inline: -$site-margin;
  }
}

.car-marketing-terms-and-conditions-button {
  @include card-panel-button;

  display: block;
  margin-top: 0;
  padding: $site-margin-half;
  width: 100%;
  height: auto;
  line-height: 21px;
}

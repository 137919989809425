.badge-ev {
  align-self: flex-start;
  background-color: #02bceb;
  padding: 0.4em 0.5em;
  font-size: 0.7rem;

  .badge-icon-ev {
    padding-right: 0.25em;
    font-size: inherit;
  }
}

// TODO fix colours in this file
$color-insurance: $color-positive !default;
$insurance-positive-font-size: 15px !default;

.insurance-content-panel {
  margin-bottom: $site-margin;
}

.content-panel .insurance-popout-content {
  display: none;
}

.insurance-header {
  float: none;
  overflow: hidden;
}

.insurance-price {
  float: right;
}

.insurance-subheader {
  padding: 0 $site-margin $site-margin;
  line-height: 1.4;
}

.insurance-more-details-link {
  display: block;
  margin-top: $site-margin-half;
  color: $color-partner-primary;
  font-size: $font-size-desktop;
}

.insurance-positive {
  border-top: 1px solid $color-grey-300;
}

.insurance-positive-check {
  float: right;
  padding: $site-margin-half $site-margin;
  color: $color-insurance;
  font-size: $insurance-positive-font-size;
}

.insurance-positive-header {
  float: none;
  margin: 0;
  padding: $site-margin-half $site-margin;
  overflow: hidden;
  color: $color-insurance;
  font-size: $insurance-positive-font-size;
  font-weight: bold;

  i {
    display: block;
    float: left;
  }

  .insurance-positive-header-text {
    display: block;
    float: none;
    overflow: hidden;
  }

  .insurance-positive.active & i {
    transform: rotate(90deg);
  }
}

.insurance-positive-description {
  display: none;
  margin: 0;
  padding: 0 $site-margin $site-margin-half ($site-margin + $insurance-positive-font-size);

  .insurance-positive.active & {
    display: block;
  }
}

.insurance-actions {
  @include row;
  @include basic-panel-content;

  padding: $site-margin;
  user-select: none;
}

.insurance-popout-content {
  text-align: left;

  .insurance-popout-text {
    padding: $site-margin;
  }

  h1 {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 16px;
  }

  p {
    margin-top: 0;
    line-height: 1.6;
  }

  ul {
    margin: 1em 0;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;
    margin-top: calc($site-margin / 2);
    margin-left: 20px;

    &::before {
      @include icon;

      position: absolute;
      left: -25px;
      margin: 0 calc($site-margin / 2);
      color: $color-insurance;
      content: '\e80b'; // .icon-check-solid
    }
  }

  @include media($media-not-mobile) {
    width: 500px;
    max-width: 100%;
    height: 500px;
    max-height: 100%;
  }

  @include media($media-mobile) {
    display: inline-block;
  }
}

.insurance-popout-header {
  border-bottom: 1px solid $color-grey-300;
  padding: ($site-margin * 2) $site-margin;
  text-align: center;

  .insurance-popout-header-tagline {
    margin: 0;
    color: $color-partner-primary;
    font-size: 18px;
  }

  .insurance-popout-header-subheader {
    margin: calc($site-margin / 2) 0 0;
    font-size: 16px;
  }
}

.insurance-reassurance {
  display: table;
  margin-bottom: $site-margin;
  padding: 0 $site-margin;
  width: 100%;
}

.insurance-reassurance-price {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  color: $color-partner-primary;
  font-size: $insurance-positive-font-size;
  font-weight: bold;
}

.insurance-disclaimer {
  display: table-cell;
  margin-top: $site-margin;
  overflow: hidden;
  vertical-align: middle;
  font-size: 0.8em;

  a {
    color: $color-partner-primary;
  }
}

.insurance-confirm-wrapper {
  @include clearfix;

  margin-bottom: $site-margin-double;
  padding: 0 $site-margin;
}

.no-insurance-book-button,
.with-insurance-book-button {
  @include action-button;

  display: block;
  float: right;
  margin-left: $site-margin;
  border: 1px solid $color-call-to-action;
  padding: 0 $site-margin;
  line-height: 1;

  @include media($media-mobile) {
    margin-bottom: $site-margin-half;
    margin-left: 0;
    width: 100%;
  }
}

.no-insurance-book-button {
  border-color: $color-grey-300;
  background: $color-call-to-action-text;
  color: $color-call-to-action;

  &:hover {
    background: mix-with-black($color-call-to-action-text, 2%);
    color: $color-call-to-action;
  }
}

.insurance-book-title {
  line-height: 1.6;
  font-size: 1.6em;
}

.insurance-book-description {
  padding-bottom: 4px;
  line-height: 1.6;
  font-size: 1.2em;
}

.insurance-button-wrapper {
  display: none;
}

.insurance-loaded .confirm-wrapper {
  display: none;
}

.insurance-loaded:not(.limited-extras) .insurance-button-wrapper {
  display: block;
}

$color-sidebar-background: $color-partner-primary !default;
$color-shadow-light: rgb(0 0 0 / 19%);
$color-shadow-dark: rgb(0 0 0 / 23%);

$sidebar-left-shadow: 0 10px 20px $color-shadow-light, 0 6px 6px $color-shadow-dark;
$sidebar-right-shadow: 0 -10px 20px $color-shadow-light, 0 -6px 6px $color-shadow-dark;
$sidebar-transition: transform 0.3s $ease-out-quart !default;

%sidebar-animate {
  transition: $sidebar-transition;
}

%sidebar-animation {
  transform: translateX($sidebar-width);
}

.sidebar {
  @extend %sidebar-animate;

  position: fixed;
  z-index: 50;
  background-color: $color-sidebar-background;
  width: $sidebar-width;
  height: 100%;
  overflow-y: auto;
}

.sidebar-left {
  left: -$sidebar-width;
}

.sidebar-right {
  right: -$sidebar-width;
}

.root {
  position: relative;
  z-index: 60;
  overflow: hidden;

  // allow click through onto sidebar
  pointer-events: none;
}

.animate-root {
  @extend %sidebar-animate;

  // re-engage click on main content wrapper
  pointer-events: auto;
}

.sidebar-shown-left {
  overflow-y: hidden;

  .animate-root {
    @extend %sidebar-animation;

    box-shadow: $sidebar-left-shadow;
  }

  .sidebar {
    @extend %sidebar-animation;
  }
}

%sidebar-animation-right {
  transform: translateX(-$sidebar-width);
}

.sidebar-shown-right {
  overflow-y: hidden;

  .animate-root {
    @extend %sidebar-animation-right;

    box-shadow: $sidebar-right-shadow;
  }

  .sidebar {
    @extend %sidebar-animation-right;
  }
}

.country-of-residence-selector-wrapper {
  display: inline-block;
  width: 100%;

  .country-of-residence-selector {
    display: none;
  }

  label {
    display: block;
  }

  select {
    margin-top: 6px;
    width: 100%;
    color: $color-black;
    font-size: 0.8rem;
  }

  &.active {
    .different-residence {
      display: none;
    }

    .country-of-residence-selector {
      display: block;
    }
  }

  @include media($media-mobile) {
    width: 100%;
  }
}

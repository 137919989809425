$color-package-info-border: $color-grey-300 !default;
$color-car-book-panel: $color-background-panel-subdued !default;
$color-car-inner-panel: $color-background-panel;

$car-panel-border-radius: $site-border-radius !default;
$hide-shadow-on-rate-card: false !default;

@import 'component.rateDetails.carFeatures';
@import 'component.rateDetails.headlinePrice';
@import 'component.rateDetails.evBadge';
@import 'component.rateDetails.image';
@import 'component.rateDetails.package';
@import 'component.rateDetails.protectionFeatures';
@import 'component.rateDetails.rateFeatures';
@import 'component.rateDetails.specialOffer';
@import 'component.rateDetails.title';
@import 'component.rateDetails.vendorBranding';

.car {
  @include row;

  border-radius: $site-border-radius;
  background: $color-car-book-panel;

  @include media($media-mobile) {
    background: $color-background-panel;
  }
}

.car-expanded {
  padding: $site-margin-half;

  @include media($media-mobile) {
    padding-right: $site-margin-half;
  }
}

.car-inner-panel,
.car-marketing-terms-and-conditions-button,
.base-book-button,
.more-details-button {
  $shadow-color-light: rgb(0 0 0 / 18%);
  $shadow-color-dark: rgb(0 0 0 / 22%);

  user-select: none;

  @if $hide-shadow-on-rate-card == true {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 1px 3px $shadow-color-light, 0 1px 2px $shadow-color-dark;

    &:hover {
      box-shadow: 0 3px 8px $shadow-color-light;
    }

    &:active {
      box-shadow: 0 4px 10px $shadow-color-dark;
    }
  }
}

.car-inner-panel {
  position: relative;
  border-radius: $site-border-radius;
  background: $color-car-inner-panel;
  min-height: 220px;

  &:hover {
    background: darken($color-car-inner-panel, 1%);

    .more-details-button {
      background: $color-secondary-button-hover;
    }
  }
}

.front-panel {
  @include clearfix;
}

.car-image-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.car-info-panel {
  @include span-columns(8.5);

  margin-right: 0;
  padding: $site-margin;

  @include media($media-mobile) {
    @include span-columns(12);
  }
}

.more-details-button {
  position: absolute;
  top: 0;
  right: 0;
  transition: background 0.3s ease-in-out;
  border-top-right-radius: $site-border-radius;
  border-bottom-left-radius: $site-margin;
  background: $color-secondary-button;
  width: 30px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  color: contrast-color($color-secondary-button);
}

// Header
.car-header {
  @include row;
}

$color-partner-primary: #004d70;
$color-partner-secondary: #7199a6;

$color-header-background: #fff;
$color-header-carhire-link: $color-partner-primary;
$color-header-contact-infos: $color-partner-primary;
$color-usp-icon: lighten($color-partner-secondary, 20%);

// New header - move to global styles
$header-height: 65px;
$header-vertical-padding: 0.75em;

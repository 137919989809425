$color-reservation-label-subtext: $color-text-dark-secondary !default;
$color-reservation-form-background: $color-white !default;
$color-reservation-form-border: $color-grey-300 !default;

.reservation-page-section {
  @include clearfix;

  display: none;

  &.loaded {
    display: block;
  }

  &:nth-child(1) {
    margin-bottom: ($site-margin * 2);
  }

  .content-panel-content {
    label {
      display: block;
    }

    p {
      margin: 1rem 0;
    }
  }

  @include media($media-portable) {
    margin: 0 auto;
    max-width: 700px;
  }
}

.reservation-root {
  @include span-columns(7);

  @include media($media-portable) {
    @include span-columns(12);

    margin-bottom: $site-margin-double;
  }
}

.reservation-form-label {
  display: block;
  margin-top: $site-margin;
  font-size: 1.08em;
  font-weight: bold;
}

.reservation-form-label-subtext {
  display: block;
  margin: 4px 0;
  color: $color-reservation-label-subtext;
  font-size: 0.92em;
}

.reservation-form-input {
  margin: 4px 0;
  width: 100%;
  font-weight: normal;
}

.reservation-terms {
  display: block;
  padding-top: $site-margin;
}

.reservation-terms-checkbox {
  position: relative;
  top: 6px;
  float: left;
  margin-right: $site-margin;
}

.reservation-terms-text {
  display: block;
  float: none;
  overflow: hidden;
  font-weight: normal;

  a {
    color: $color-partner-primary;
  }
}

.reservation-button {
  @include action-button;

  display: block;
  margin: 15px 0 0;
  width: 100%;
  text-align: center;

  i {
    font-size: 16px;
  }
}

.reservation-form.loading {
  .reservation-button {
    position: relative;

    .reservation-button-label,
    .icon-chevron-right {
      visibility: hidden;
    }

    .reservation-button-loading {
      @include loader($color-loading: $color-call-to-action-text);

      display: block;
      position: absolute;
      margin: 8px auto;
      inset: 0;
    }
  }
}

.reservation-payment-providers {
  display: inline-block;
  margin-top: $site-margin;

  .reservation-payment-providers-label {
    display: inline-block;
    float: left;
    margin: 0;
    line-height: 36px;
    font-weight: bold;
  }
}

.reservation-age {
  @include clearfix;

  .reservation-age-label {
    @include span-columns(4);

    select {
      margin-top: calc($site-margin / 2);
      width: 100%;
    }
  }
}

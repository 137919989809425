$prefix: wlp-bs-;

// importing the scss variables globally as some components uses those
// those are compile time so no conflicts with partners bootstrap happens
@import './bootstrap.utils-and-variables';

// for css classes (like .btn) to work we need css variables
// those are defined globally using :root and body selectors
// thanks to $prefix we can import those and avoid conflicts
@import './bootstrap.css-variables';

// to not override bootstrap classes from other "instance"
// we import those scoped with the selectors but without changing specificity
:where([data-cnx-widget], [data-cnx-container]) {
  @import './bootstrap';
}

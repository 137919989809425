@mixin notice-color($color) {
  border-color: $color;
  background: mix-with-white($color, 80);
  color: $color;
}

.marketing-notice {
  margin-bottom: $site-margin;
  border: 1px solid $color-positive;
  border-radius: $site-border-radius;
  padding: $site-margin-half $site-margin;
  text-align: center;
  font-size: 17px;
  font-weight: bold;

  .map-search-page & {
    display: none;
  }

  i {
    padding-right: 5px;
  }

  .link-button {
    text-decoration: underline;
    font-weight: bold;
  }

  &.positive {
    @include notice-color($color-positive);
  }

  &.urgent {
    @include notice-color($color-urgent);
  }

  &.info,
  &.covid {
    @include notice-color($color-info-header);
  }

  &.covid {
    font-weight: normal;
  }

  &.covid:not(.active) {
    .text {
      @include ellipsis;
    }

    .read-less {
      display: none;
    }
  }

  &.covid.active {
    .read-more {
      display: none;
    }
  }

  @include media($media-mobile) {
    font-size: 16px;
  }
}

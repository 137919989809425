@import 'component.datePicker';
@import 'component.languagePicker';
@import 'component.optionalInput';

$search-form-icon-side: left !default;
$search-form-color-icons: false !default;
$search-form-mode: vertical !default;

$color-search-input-header: $color-info-header !default;
$color-search-form-label: $color-text-main !default;
$color-search-form-checkbox-label: $color-search-form-label !default;

$input-border-width: 1px;

// This is used by sub-components of the search form
$input-border: $input-border-width solid $color-input-border;

%search-form-input {
  outline: none;
  border: $input-border;
  border-radius: $site-border-radius;
  background: $color-white;
  width: 100%;
  height: $default-input-height;
  text-align: left;
  line-height: $default-input-height;
  appearance: none;
}

// Horizontal search form isn't available on mobile at all,
// there's simply not enough space
@mixin horizontal-tablet() {
  // Put date/time pickers on 2 lines when on tablet
  @include media($media-tablet) {
    .date-input,
    .time-input {
      width: 100%;
    }

    .time-input {
      margin-top: calc($site-margin / 2);
    }
  }
}

@mixin popout-autocomplete-results() {
  .inline-autocomplete-results {
    position: absolute;
    bottom: 105%;
    z-index: 100;
    border: 1px solid $color-grey-300;
    background: $color-white;
    max-height: inherit;
    overflow: inherit;

    &::before {
      @include arrow(7px, bottom, 48%, $color-grey-300);
    }
  }

  .autocomplete-item {
    padding: 0;
    line-height: 32px;

    &:last-child {
      border-bottom: 0;
    }
  }
}

@mixin partner-label {
  font-size: $site-margin;
  font-weight: bold;
}

@mixin horizontal($horizontal-media) {
  @include hide-overlay-autocomplete;

  @include media($horizontal-media) {
    @include popout-autocomplete-results;

    .location-header,
    .time-label,
    .date-label {
      margin: 0 0 calc($site-margin / 2);
      line-height: calc($default-input-height / 2);
    }

    .default-dropoff-panel {
      margin: calc($site-margin / 2) 0;
      line-height: calc($default-input-height / 2);
    }

    .date-label {
      @include partner-label;
    }

    .location-wrapper {
      @include span-columns(4);

      display: inline-block;
    }

    .timeframe-wrapper {
      @include span-columns(12);

      .timeframe-header {
        display: none;
      }

      .search-form-to-date,
      .search-form-from-date {
        @include span-columns(6);

        position: relative;
      }
    }

    .grouped-inputs-wrapper,
    .promo-code-wrapper {
      @include span-columns(6);
      @include clearfix;
    }

    .age-wrapper,
    .country-of-residence-selector-wrapper {
      @include span-columns(6);
    }

    .submit-wrapper {
      @include span-columns(2);

      margin-top: calc($site-margin / 2) + calc($default-input-height / 2);
      margin-right: 0;

      .submit-button {
        height: $default-input-height;
        line-height: $default-input-height;
      }
    }

    .autocomplete-box {
      margin-top: 0;
    }
  }
}

@mixin hide-overlay-autocomplete() {
  .overlay-autocomplete-results,
  .overlay-autocomplete {
    display: none;
  }
}

@mixin overlay-autocomplete() {
  position: relative;

  .inline-autocomplete {
    display: none;
  }

  .overlay-autocomplete-results {
    position: absolute;
    background: $color-white;
    height: 100%;
    max-height: 100%;
    inset: 0;

    &.hidden {
      display: none;
    }
  }
}

// Here's the actual CSS
.search-form {
  $color-input-icon-border: $input-border !default;

  @include row;

  @if $search-form-color-icons {
    $input-icon-border: 1px solid $color-input-icon-background;
  }

  .vehicle-group-wrapper {
    display: none;

    &:has(:where(.group-choice:nth-of-type(2))) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .vehicle-group-picker {
    display: flex;
    align-items: stretch;
    gap: $site-margin;

    .group-choice {
      display: inline-flex;
      align-items: center;
      gap: calc($site-margin / 2);
    }

    .group-choice-radio {
      margin-top: 3%;
    }

    .icon-car {
      font-size: 1.2em;
    }

    .icon-truck {
      font-size: 1.9em;
    }
  }

  .default-dropoff-panel {
    margin: $site-margin 0 calc($site-margin / 2);
    color: $color-search-form-checkbox-label;

    input {
      margin-right: calc($site-margin / 2);
    }
  }

  .dropoff-location-panel {
    @extend %panel-with-validation;

    margin-top: $site-margin;
  }

  .location-header,
  .time-label,
  .date-label {
    display: inline-block;
    color: $color-search-form-label;
  }

  .date-label {
    display: block;
    margin: calc($site-margin / 2) 0 calc($site-margin / 4);
    font-size: 14px;
  }

  .datetime-input {
    @include row;
  }

  .date-input {
    @include span-columns(7);

    height: $default-input-height;
  }

  .time-input {
    @include span-columns(5);

    height: $default-input-height;
  }

  .autocomplete-box {
    margin: calc($site-margin / 2) 0 0;

    input {
      @extend %search-form-input;

      line-height: $default-input-height;
    }
  }

  .date-input,
  .time-input {
    display: inline-table;
    border-radius: $site-border-radius;
    background: $color-input-background;
    table-layout: fixed;

    input,
    select {
      @extend %search-form-input;

      // Required to run as a widget
      @extend %form-element;

      display: table-cell;
      border: 0;
      border-top: $input-border;
      border-bottom: $input-border;
      cursor: pointer;

      @if $search-form-icon-side == left {
        border-right: $input-border;
        border-radius: 0 $input-border-radius $input-border-radius 0;
      } @else {
        border-left: $input-border;
        border-radius: $input-border-radius 0 0 $input-border-radius;
      }
    }

    select {
      cursor: pointer;
      line-height: $default-input-height;
      appearance: none;
    }

    input::-webkit-clear-button {
      display: none;
      appearance: none;
    }

    input::-webkit-inner-spin-button {
      appearance: none;
    }

    input::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }

  .input-icon-right,
  .input-icon {
    display: table-cell;
    border-top: $color-input-icon-border;
    border-bottom: $color-input-icon-border;
    cursor: pointer;
    width: 25px;
    vertical-align: middle;

    // Drop 2px off for the border!
    line-height: $default-input-height - 2px;
    white-space: nowrap;

    @if $search-form-color-icons {
      background: $color-input-icon-background;
      color: $color-white;
    } @else {
      color: $color-input-icon;
    }

    @include media($media-not-portable) {
      width: 22px;
    }
  }

  .input-icon-right {
    border-right: $color-input-icon-border;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
    padding: 0 calc($site-margin * 2 / 3) 0 0;

    @if $search-form-icon-side == left {
      display: none;
    }
  }

  .input-icon {
    border-left: $color-input-icon-border;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
    padding: 0 0 0 calc($site-margin * 2 / 3);

    @if $search-form-icon-side == right {
      display: none;
    }
  }

  .location-wrapper,
  .timeframe-wrapper,
  .grouped-inputs-wrapper {
    @extend %panel-with-validation;
    @include row;

    padding-bottom: $site-margin;
  }

  .optional-inputs-wrapper {
    color: $color-search-form-checkbox-label;
  }

  .vehicle-group-header,
  .location-header,
  .promo-code-label,
  .source-market-label,
  .reservation-form-label,
  .timeframe-header,
  .optional-input-label {
    @include partner-label;

    margin: 0;
    color: $color-search-input-header;
  }

  .submit-wrapper {
    @include row;

    margin: $site-margin 0;

    .submit-button {
      $color-search-button: $color-call-to-action !default;
      $color-search-button-hover: action-button-background($color-search-button, 'hover') !default;
      $color-search-button-pressed: action-button-background($color-search-button, 'pressed') !default;
      $color-search-button-text: contrast-color($color-search-button) !default;
      $color-search-button-hover-text: contrast-color($color-search-button-hover) !default;
      $color-search-button-pressed-text: contrast-color($color-search-button-pressed) !default;

      @include span-columns(12);

      @include action-button(
        $background: $color-search-button,
        $color: $color-search-button-text,
        $background-hover: $color-search-button-hover,
        $color-hover: $color-search-button-hover-text,
        $background-pressed: $color-search-button-pressed,
        $color-pressed: $color-search-button-pressed-text
      );
    }
  }

  &.inline-autocomplete {
    @include hide-overlay-autocomplete;
  }

  &.horizontal-portable {
    @include horizontal-tablet;
    @include horizontal($media-not-mobile);
  }

  &.horizontal {
    @include horizontal($media-not-portable);
  }

  &.fixed {
    @include overlay-autocomplete;
  }

  .age-input-panel {
    display: block;

    &.hidden {
      display: none;
    }
  }

  .age-wrapper,
  .country-of-residence-selector-wrapper {
    margin-bottom: calc($site-margin / 2);
  }

  .age-wrapper:last-child {
    margin-bottom: 0;
  }
}

// Shenanigans
@if not(search-form-mode == vertical) {
  .cnx-wlp {
    .search-form {
      // Allow the search form to be presented horizontally
      @if $search-form-mode == horizontal {
        @include horizontal($media-not-portable);
      } @else if $search-form-mode == fully-horizontal {
        // Can do horizontal on tablet but requires some concessions to
        // the length of the labels etc.
        @include horizontal-tablet;
        @include horizontal($media-not-mobile);
      }
    }
  }
}

.search-form-desktop-panel {
  @include media($media-medium) {
    .input-icon {
      padding-left: calc($site-margin / 2);
      width: 20px;
    }

    .time-input,
    .date-input {
      select,
      input {
        padding-left: calc($site-margin / 2);
      }
    }
  }
}

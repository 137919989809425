.promo-code-wrapper {
  .promo-code {
    display: none;
    width: 100%;

    .promo-code-field {
      @extend %search-form-input;

      margin-top: calc($site-margin / 2);
    }
  }

  &.active {
    .have-promo-code {
      display: none;
    }

    .promo-code {
      display: block;
    }
  }
}

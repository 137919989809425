$confirmation-overlay-gradient-top: $background-overlay-gradient-bottom !default;
$confirmation-overlay-gradient-bottom: $background-overlay-gradient-top !default;
$confirmation-overlay-gradient: linear-gradient(to bottom, $confirmation-overlay-gradient-top 40%, $confirmation-overlay-gradient-bottom 90%) !default;
$color-confirmation-details-border: $color-grey-300 !default;
$confirmation-details-border-radius: 4px !default;
$color-confirmation-details-header-background: $color-grey-100 !default;
$color-confirmation-details-section-background: $color-background-panel !default;

$confirmation-full-margin: 25px !default;
$confirmation-partial-margin: $confirmation-full-margin - $default-container-padding;
$background-position-confirmation-desktop: center center !default;
$background-position-confirmation-mobile: center center !default;
$confirmation-banner-text-shadow: 1px 1px 0 #000 !default;

.confirmation-banner {
  @include content-container;

  position: relative;
  background: $confirmation-overlay-gradient, $background-image;
  background-position: $background-position-confirmation-desktop;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  text-shadow: $confirmation-banner-text-shadow;
  color: $color-white;

  .confirmation-header {
    margin: 10px 0;
  }

  .confirmation-header-subtext {
    margin: 0;
    font-size: 1.1em;
  }

  @include media($media-mobile) {
    background-position: $background-position-confirmation-mobile;
  }
}

.confirmation-header-wrapper {
  @include outer-container;

  padding-top: 64px;
  text-align: center;
}

.confirmation-section {
  @include media($media-not-mobile) {
    margin: 0 auto;
    max-width: 700px;
  }
}

.confirmation-section-header {
  margin: 12px $confirmation-partial-margin 8px;
}

.confirmation-section-subtext {
  margin: 8px $confirmation-partial-margin 16px;
}

.confirmation-details-wrapper {
  border: 1px solid $color-confirmation-details-border;
  border-radius: $confirmation-details-border-radius;

  .confirmation-details-subheader {
    background: $color-confirmation-details-header-background;
    overflow: hidden;

    &:nth-child(1) {
      border-top-left-radius: $site-border-radius;
      border-top-right-radius: $site-border-radius;
    }
  }

  .confirmation-details-subheader-text {
    margin: 0;
    padding: 8px $confirmation-partial-margin;
    font-size: 1em;
  }

  .confirmation-details-section {
    background: $color-confirmation-details-section-background;
    padding: 10px $confirmation-partial-margin;

    &:last-child {
      border-bottom-left-radius: $site-border-radius;
      border-bottom-right-radius: $site-border-radius;
    }
  }
}

.confirmation-container {
  .charge-remove-button {
    display: none;
  }
}

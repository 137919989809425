@mixin loading-logo($partner, $id) {
  .#{$partner}-loading-logo {
    background-image: url('https://static.carhire-solutions.com/images/supplier/logo/logo#{$id}.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@include loading-logo('alamo', 36);
@include loading-logo('avis', 10);
@include loading-logo('enterprise', 61);
@include loading-logo('europcar', 30);
@include loading-logo('national', 62);
@include loading-logo('sixt', 11);
@include loading-logo('hertz', 8);
@include loading-logo('thrifty', 260);
@include loading-logo('dollar', 64);
@include loading-logo('global', 51);
@include loading-logo('buchbinder', 90);
@include loading-logo('firefly', 197);
@include loading-logo('goldcar', 34);
@include loading-logo('autoclick', 323);
@include loading-logo('ok', 257);
@include loading-logo('keddy', 290);
@include loading-logo('interrent', 247);
@include loading-logo('easirent', 212);
@include loading-logo('autoclick', 323);
@include loading-logo('solmar', 22);
@include loading-logo('jace', 321);
@include loading-logo('maggiore', 286);
@include loading-logo('megadrive', 252);
@include loading-logo('lagorent', 216);
@include loading-logo('greenmotion', 241);
@include loading-logo('budget', 57);
@include loading-logo('centauro', 59);
@include loading-logo('sicily', 219);
@include loading-logo('orlando', 221);
@include loading-logo('dollar', 64);

.partner-loading-logos {
  @include content-container;

  margin-top: 0;
  padding-left: 0;
  list-style: none;
}

.partner-loading-logo {
  @include span-columns(2);
  @include omega(6n);

  position: relative;
  margin-top: ($site-margin * 2);
  border: 2px solid $color-white;
  border-radius: $site-border-radius;
  background-color: $color-white;
  padding: calc($site-margin / 2);
  height: 60px;
  overflow: hidden;

  &.partner-loaded {
    border-color: $color-loading-pulse;
  }

  @include media($media-mobile) {
    @include span-columns(4);
    @include omega(3n);

    padding: calc($site-margin / 2);
  }
}

.loading-logo {
  transition: opacity 0.5s $ease-out-cubic;
  opacity: 0.3;
  background-color: $color-white;
  height: 100%;

  .partner-loaded & {
    opacity: 1;
  }
}

.pulse-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pulse {
  margin: 0 auto;
  border-radius: 100%;
  background-color: $color-loading-pulse;
  width: 60px;
  height: 100%;
  animation: pulse 1.3s infinite $ease-in-out-quart;

  .partner-loaded & {
    display: none;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

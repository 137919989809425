$color-main-wrapper-background: $color-background-panel !default;
$color-main-wrapper-border: $color-grey-300 !default;
$color-main-wrapper-shadow: $color-grey-300 !default;
$color-partners-background: $color-white !default;
$color-marketing-panel-content: $color-white !default;
$color-usps: inherit !default;
$hide-inline-autocomplete-results: true !default;
$color-autocomplete-results-item-landing: contrast-color($color-main-wrapper-background) !default;
$color-usp-icon: $color-partner-secondary !default;
$marketing-panel-content-text-shadow: 1px 1px 0 #000 !default;

.landing-container {
  @include content-container;

  display: flex;
  align-items: center;
  flex-direction: column;

  @include media($media-not-mobile) {
    flex-direction: row;
    padding: $site-margin * 2 $site-margin;
  }
}

.homepage-marketing-panel {
  @include span-columns(8);
  @include omega(n);

  // So the autocomplete results float over the marketing content.
  position: relative;
  text-shadow: $marketing-panel-content-text-shadow;
  color: $color-marketing-panel-content;

  .partners {
    background: none;
    color: $color-marketing-panel-content;
  }

  @include media($media-tablet) {
    @include span-columns(6);
  }

  @include media($media-mobile) {
    @include span-columns(12);
  }
}

.landing-content-wrapper {
  @include basic-panel($background: $color-main-wrapper-background, $border-color: $color-main-wrapper-border);
  @include span-columns(4);
  @include omega(3n);
  @include pad(12px);

  position: relative;
  text-shadow: none;

  &:has(.search-form .vehicle-group-wrapper .group-choice:nth-of-type(2)) > .desktop-autocomplete-results {
    --vehicle-group-height: 43px;

    @include media($media-tablet) {
      --vehicle-group-height: 51px;
    }
  }

  .date-input input {
    @include pad(0 0 0 6px);
  }

  .autocomplete-item {
    color: $color-autocomplete-results-item-landing;
  }

  @include media($media-tablet) {
    @include span-columns(6);
  }

  @include media($media-mobile) {
    @include span-columns(12);
  }
}

.homepage-marketing-copy-wrapper {
  display: table;
  margin: 0 auto;
}

.desktop-tagline {
  text-align: center;
  font-size: 1.3em;

  .header-flavour-text {
    margin: 0.83em 0;
  }

  @include media($media-mobile) {
    display: none;
  }
}

.mobile-tagline {
  text-align: center;
  text-shadow: $marketing-panel-content-text-shadow;
  color: $color-white;

  @include media($media-not-mobile) {
    display: none;
  }
}

.usps {
  margin: 0;
  padding: $site-margin;
  list-style: none;
  text-align: center;
  color: $color-usps;
  font-weight: bold;

  .usp-item {
    @include media($media-not-mobile) {
      margin-bottom: 8px;
      padding-right: $site-margin;
      font-size: 20px;
    }

    @include media($media-mobile) {
      padding-top: $site-margin;
    }
  }

  i {
    padding-right: 4px;
    color: $color-usp-icon;
  }
}

@if $hide-inline-autocomplete-results {
  .homepage-search-wrapper {
    .inline-autocomplete-results {
      @include media($media-not-mobile) {
        // Disabled as the display here needs to override the "visible" class addition
        // scss-lint:disable ImportantRule
        display: none !important;
      }
    }
  }
}

.desktop-autocomplete-results {
  --vehicle-group-height: 0px;

  display: none;
  position: absolute;
  top: 0;
  right: -105%;
  z-index: 999;
  margin-top: calc(22px + var(--vehicle-group-height));

  &.visible {
    display: block;
  }

  &.dropoff-results {
    margin-top: calc(125px + var(--vehicle-group-height));
  }

  @include media($media-not-portable) {
    @include span-columns(12);

    display: none;
  }

  @include media($media-tablet) {
    @include span-columns(12);

    display: none;
    margin-top: calc(26px + var(--vehicle-group-height));

    &.dropoff-results {
      margin-top: calc(136px + var(--vehicle-group-height));
    }
  }

  @include media($media-mobile) {
    // Disabled as the display here needs to override the "visible" class addition
    display: none !important;
  }
}

%arrow {
  position: absolute;
  left: -12px;
  z-index: 99;
  border: solid;
  border-width: 12px 12px 12px 0;
  border-color: transparent $color-white transparent transparent;
  content: '';
}

.datepicker-pointer.pickup {
  &::before {
    @extend %arrow;

    top: 190px;
  }
}

.datepicker-pointer.dropoff {
  &::before {
    @extend %arrow;

    top: 260px;
  }
}

.autocomplete-results-pointer {
  &::before {
    position: absolute;
    top: 19px;
    left: -12px;
    z-index: 99;
    border: solid;
    border-width: 12px 12px 12px 0;
    border-color: transparent $color-main-wrapper-background transparent transparent;
    content: '';
  }
}

.landing-solid-background-panel {
  background: $color-main-wrapper-background;
  width: 100%;
}

.landing-more-info-container {
  @include content-container;
}

.partners {
  @include row;

  background: $color-partners-background;
  padding-top: 18px;
  text-align: center;

  .partner-images {
    @include content-container;

    margin-top: 0;
    list-style: none;
  }

  .partner-image {
    @include span-columns(2);
    @include omega(6n);

    height: 50px;

    @include media($media-tablet) {
      @include span-columns(4);
      @include omega(3n);

      margin-top: $site-margin;
    }

    @include media($media-mobile) {
      @include span-columns(6);
      @include omega(2n);

      margin-top: $site-margin;
      padding: 2px 18px 0;
    }
  }

  .heading {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 1.2em;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @include media($media-not-mobile) {
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding-bottom: 0;
    max-width: 80%;
  }
}

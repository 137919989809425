$category-filter-spacer-width: 0.75%;
$color-car-category: $color-background-panel !default;
$color-car-category-active: $color-car-category !default;
$color-car-category-inactive: $color-grey-200 !default;

@mixin car-category-filter($columns) {
  .car-category-filter-#{$columns} {
    @include row;

    margin-top: 0;
    border: 0;
    padding-left: 0;
    width: 100%;
    list-style: none;

    .car-category-filter-item {
      width: calc((100% - ($category-filter-spacer-width * ($columns - 1))) / $columns);
    }
  }

  .has-active-filters .car-category-filter-#{$columns} {
    .car-category-filter-item {
      background: $color-car-category-inactive;
    }

    .active-filter {
      @include set-color($color-car-category-active);

      border: 1px solid $color-info-button;
    }
  }
}

@include car-category-filter(5);
@include car-category-filter(6);
@include car-category-filter(7);
@include car-category-filter(8);

.car-category-filter-item {
  @include basic-button($hover-color: $color-car-category);
  @include shadow-on-active;

  float: left;
  margin-left: $category-filter-spacer-width;
  background: $color-car-category;
  cursor: pointer;
  padding: $site-margin;
  user-select: none;

  &:nth-child(1) {
    margin-left: 0;
  }

  .car-category-filter-item-title {
    display: block;
    width: 100%;
    height: 36px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    line-height: 18px;
    color: $color-info-header;
    font-size: 14px;
  }

  .car-category-filter-item-image {
    padding: calc($site-margin / 2);
    width: 100%;
  }

  .car-category-filter-item-subtext {
    display: inline-block;
    width: 100%;
    height: 34px;
    text-align: center;
    line-height: 17px;
  }
}

@include media($media-not-mobile) {
  .car-category-filter-4,
  .car-category-filter-3,
  .car-category-filter-2,
  .car-category-filter-1 {
    display: none;
  }

  .filter-group-section-carcategories-5,
  .filter-group-section-carcategories-6,
  .filter-group-section-carcategories-7,
  .filter-group-section-carcategories-8 {
    display: none;
  }

  .map-search-page {
    .filter-group-section-carcategories-5,
    .filter-group-section-carcategories-6,
    .filter-group-section-carcategories-7,
    .filter-group-section-carcategories-8 {
      display: block;
    }
  }
}

@include media($media-mobile) {
  .car-category-filter-8,
  .car-category-filter-7,
  .car-category-filter-6,
  .car-category-filter-5,
  .car-category-filter-4,
  .car-category-filter-3,
  .car-category-filter-2,
  .car-category-filter-1 {
    display: none;
  }
}

.optional-input-wrapper {
  .optional-input {
    display: none;
    width: 100%;

    .optional-input-field {
      @extend %search-form-input;

      margin-top: calc($site-margin / 2);
    }
  }

  &.active {
    .have-optional {
      display: none;
    }

    .optional-input {
      display: block;
    }
  }
}

$color-sticky-filter-shadow: $color-grey-300 !default;

.filters {
  margin: 0;
  padding: 0;
  list-style: none;

  .filter {
    @include row;
  }
}

.filter-header-collapsible,
.filter-header {
  margin: calc($site-margin / 2) 0;
  padding: $site-margin 0;
  color: $color-info-header;
}

.filter-header-collapsible {
  i {
    float: right;
  }
}

.filter-group-section {
  @include row;

  margin: auto;
  border-bottom: 1px solid $color-grey-300;
  width: 90%;

  &.active {
    .filter-checklist {
      display: block;
    }

    .filter-header-collapsible i {
      transform: rotate(180deg);
    }
  }
}

.filter-checklist {
  @include row;

  display: none;
  margin: 0;
  margin-bottom: $site-margin;
  padding-left: 0;
  list-style: none;

  .filter-checklist-item {
    @include span-columns(6);
    @include omega(2n);
    @include shadow-on-hover;
    @include shadow-on-active($shadow-size: calc($site-margin / 2));

    display: block;
    margin-bottom: 6px;
    border: solid 1px $color-grey-300;
    border-radius: $site-border-radius;
    background: $color-info-button-background;
    cursor: pointer;
    padding: 8px 0;
    width: 100%;
    height: 100%;
    text-align: center;
    user-select: none;

    @include media($media-not-mobile) {
      @include span-columns(12);
      @include omega(1n);
    }
  }

  .active-filter {
    border-color: $color-info-button;
    color: $color-info-button;
    font-weight: bold;
  }
}

.filter-price-range-label {
  margin: 0.5em 0 0.25em;
  font-size: 16px;
}

.filter-price-range {
  width: 100%;
}

.filter-price-section {
  @include span-columns(6);
}

.filter-checkbox {
  margin-right: 4px;
}

.modify-search-panel {
  @include highlight-on-render;

  transition: width 0.3s;
  margin-bottom: 12px;
  width: 100%;

  @include media($media-not-portable) {
    display: none;

    .map-search-page & {
      display: block;

      .filter-toggle {
        display: none;
      }
    }
  }
}

@if $site-small-search-page {
  .modify-search-panel {
    display: block;
  }

  @include media($media-not-portable) {
    .filter-toggle {
      display: none;
    }
  }
}

.filter-sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: -1px 1px 2px 0 $color-sticky-filter-shadow;
  width: 100%;

  .accordion-group {
    .filter-toggle,
    .search-summary-toggle {
      border-radius: 0;
    }
  }
}

.apply-filters-button {
  @include shadow-on-active($shadow-size: calc($site-margin / 2));

  display: block;
  margin: $site-margin auto 0;
  border: 0 none;
  border-radius: $site-border-radius;
  background-color: $color-call-to-action;
  cursor: pointer;
  width: 90%;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  color: $color-white;
  font-weight: 700;
}

.clear-filters-button {
  @include shadow-on-active($shadow-size: calc($site-margin / 2));

  display: block;
  margin: $site-margin auto 0;
  border: 0 none;
  border-radius: $site-border-radius;
  background-color: $color-subdued-button-background;
  cursor: pointer;
  width: 90%;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  color: $color-subdued-button;
  font-weight: 700;
}

.no-results-header {
  margin: 16px auto 0;
  border: 1px solid $color-partner-secondary;
  border-radius: 50px;
  background-color: $color-white;
  padding-top: 4px;
  width: 48px;
  height: 48px;
  text-align: center;
  color: $color-partner-secondary;
  font-size: 200%;
  font-weight: bolder;
}

.no-results-text {
  text-align: center;
}

.prevent-scroll {
  overflow: hidden;
}

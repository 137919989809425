$color-car-price: null !default;

.car-price-wrapper {
  display: block;

  // Lined up with the car features (seats etc.)
  @include media($media-not-mobile) {
    // Have to drop 4px off to cater for some oddities in the rendering of the header
    // which cause the feature panel to be offset, as well as the extra height of the
    // ribbon.
    // TODO: Come back to this and add a calculation.
    margin-top: $car-desktop-features-spacing - 4px;
  }

  // Left side of bottom panel
  @include media($media-mobile) {
    @include span-columns(6);

    padding: $car-panel-standard-margin;
  }
}

.car-price-information,
.car-price-range {
  display: block;
  height: $car-header-line-height;
  line-height: $car-header-line-height;
  font-size: $car-header-line-height;
}

.car-price-range {
  padding-top: 2px;
  font-weight: bold;
}

.car-price-savings-badge {
  display: block;
  width: fit-content;
  font-size: $car-header-line-height * 0.9;
  margin-inline: auto;
}

.car-price-price {
  $color-car-discounted-price: mix-with-white($color-text-main, 40%) !default;
  $car-price-size-multiplier: 1 !default;
  $car-price-top-padding: calc($car-header-line-height / 4);
  $car-price-height: ($car-desktop-feature-height - ($car-header-line-height + $car-price-top-padding)) * $car-price-size-multiplier;

  display: inline-block;
  padding-top: $car-price-top-padding;
  height: $car-price-height;
  line-height: $car-price-height;
  font-size: $car-price-height;
  font-weight: bold;

  @if $color-car-price {
    color: $color-car-price;
  }

  &.old-price {
    height: calc($car-price-height / 2);
    text-decoration: line-through;
    line-height: calc($car-price-height / 2);
    color: $color-car-discounted-price;
    font-size: calc($car-price-height / 2);
  }
}

.car-price-total {
  $car-price-top-padding: calc($car-header-line-height / 2);

  display: block;
  padding-top: $car-price-top-padding;
  font-size: $car-header-line-height;
}

// Apply this mixin when aiming for the simple partner look
// Applied by default when importing imports/simple-partner
@mixin simple-partner() {
  .fixed-bg {
    @include media($media-not-mobile) {
      display: none;
    }
  }

  @include media($media-not-mobile) {
    .text-content-header,
    .landing-container {
      background-image: $background-image;
      background-image: $background-overlay-gradient, $background-image;
      background-position: $background-position-home-desktop;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }

    .header,
    .text-content-header,
    .text-content,
    .breadcrumbs-panel,
    .view-container,
    .landing-container,
    .header-content-desktop,
    .confirmation-banner {
      position: relative;

      &::before {
        @include shadow-mid;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: '';
      }
    }
  }
}

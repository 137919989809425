.vendor-branding {
  display: block;
  float: right;
  margin: 0 15px;
  padding: $car-desktop-inner-top-padding - calc($car-header-line-height / 2) 0 3px;
  max-width: 20%;

  .vendor-image {
    display: block;
    position: relative;
    width: 100%;
    height: $car-header-line-height * 2;
  }

  img {
    max-width: 100%;
    max-height: $car-header-line-height * 2;
  }

  @include media($media-mobile) {
    max-width: 23%;
    text-align: center;
  }
}

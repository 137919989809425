$color-search-toggle: $color-info-button !default;
$color-search-toggle-background: $color-info-button-background !default;
$color-search-results-background: $color-grey-50 !default;
$color-car-size-group-price-from: $color-grey-600 !default;
$color-search-form-background: $color-background-panel !default;
$color-autocomplete-results-item-search: contrast-color($color-search-form-background) !default;
$search-summary-toggle-border-radius: $site-border-radius !default;
$toggle-border-radius: $site-border-radius !default;
$wrapper-border-radius: $site-border-radius !default;
$search-form-menu-side: left !default;

%site-small-search-page {
  .search-form-panel {
    display: none;
  }

  .filter-wrapper {
    margin-top: 0;
  }
}

.accordion-group {
  @include row;
}

.filter-toggle {
  float: right;
  border-left: 0;
  border-radius: 0 $toggle-border-radius $toggle-border-radius 0;
}

.search-summary-toggle,
.filter-toggle {
  @include accordion-toggle;

  position: relative;
  border: 1px solid $color-grey-300;
  background: $color-search-toggle-background;
  padding: 5px 10px;
  color: $color-search-toggle;

  &.open {
    border-bottom: 1px solid $color-white;
    border-bottom-left-radius: 0;
  }

  &.inactive {
    opacity: 0.5;
  }

  .toggle-header {
    float: left;
    overflow: hidden;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
  }

  .toggle-header-no-emphasis {
    color: $color-text-main;
    font-size: 14px;
    font-weight: normal;
  }

  .toggle-icon {
    float: right;
    margin-left: 15px;
    line-height: 42px;
  }
}

.search-summary-toggle {
  float: none;
  border-radius: $toggle-border-radius 0 0 $toggle-border-radius;
  overflow: hidden;

  .toggle-header {
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .toggle-icon {
    margin-left: 0;
  }
}

@if $site-small-search-page {
  @include media($media-not-portable) {
    .search-summary-toggle {
      border-radius: $toggle-border-radius;
    }
  }
}

.search-summary-wrapper {
  padding: 8px;
}

.search-datepicker-panel {
  display: none;

  &.visible {
    // Disabled as the display here needs to override the "visible" class addition
    // scss-lint:disable ImportantRule
    display: block !important;
  }
}

.search-results-list {
  @include fade-in-on-render;
}

.search-summary-wrapper,
.filter-wrapper {
  @include accordion-content;

  margin-top: -1px;
  border: 1px solid $color-grey-300;
  border-top: 0;
  border-radius: 0 0 $wrapper-border-radius $wrapper-border-radius;
  background: $color-search-toggle-background;
  padding-bottom: $site-margin * 2;
  width: 100%;
  overflow-y: hidden;
}

.search-loading-container,
.search-results-container {
  @include clearfix;

  min-height: 500px;
  overflow: hidden;
}

.search-results-container {
  display: none;

  &.loaded {
    display: block;
  }

  .autcomplete-item {
    color: $color-autocomplete-results-item-search;
  }
}

.search-loading-container {
  display: none;

  &.visible {
    display: block;
  }

  .loading-text {
    margin-top: $site-margin;
    margin-bottom: 0;
    text-align: center;
    color: $color-search-toggle;
  }
}

.search-form-panel {
  @include basic-panel;

  margin-bottom: $site-margin;
  background: $color-search-form-background;
  padding-top: $site-margin;

  .search-form {
    @include row;

    display: block;
    margin: auto;
    width: 90%;
  }
}

.search-form-desktop-panel {
  @if $site-small-search-page {
    @extend %site-small-search-page;
  }

  @if $search-form-mode == horizontal {
    @extend %site-small-search-page;
  }

  .map-search-page & {
    @extend %site-small-search-page;
  }

  .search-filters-panel {
    @include highlight-on-render;
  }

  @include media($media-not-portable) {
    @include span-columns(3);

    // Have to do this inside the media query or
    // the span-columns overrides the float right
    @if $search-form-menu-side == right {
      float: right;
      margin-right: 0;
      margin-left: 2.35765%; // stylelint-disable-line
    }
  }

  @include media($media-portable) {
    display: none;
  }
}

.search-filters-panel {
  @include media($media-not-portable) {
    .filter-wrapper {
      @include basic-panel;

      display: block;
      background: $color-background-panel;
    }
  }
}

.search-results {
  @include media($media-not-portable) {
    @include span-columns(9);
  }
}

.car-size-group {
  @include row;

  display: inline-block;
  margin-bottom: $site-margin;
  border-bottom: 2px solid $color-grey-300;
  padding-bottom: $site-margin;
  width: 100%;

  &:nth-child(n + 2) {
    margin-top: $site-margin;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
  }

  .car-size-group-price-from {
    white-space: nowrap;
    color: $color-car-size-group-price-from;
  }
}

.promo-code-selection,
.car-size-group-title {
  @include basic-panel;

  margin: 0 0 $site-margin;
  background: $color-white;
  padding: $site-margin;
  width: 100%;
  color: $color-panel-header;
  font-size: 20px;
  font-weight: bold;
}

.promo-code-selection {
  .promo-code {
    color: $color-info-header;
  }
}

.car-size-group-subtext {
  margin: 0 0 $site-margin;
  font-size: 16px;
  font-weight: normal;
}

.search-result-more-button {
  @include card-panel-button;
  @include shadow-low;

  display: block;
  margin: $site-margin;
}

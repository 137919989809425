$use-standard-validation-styles: true !default;
$color-validation-message: $color-error !default;
$color-validation-message-text: $color-white !default;

%panel-with-validation {
  position: relative;
}

.validation-message {
  position: relative;
  z-index: 98;
  margin: 8px 0 12px;
  border-radius: $site-border-radius;
  background: $color-validation-message;
  cursor: pointer;
  padding: 12px;
  width: 100%;
  text-align: center;
  color: $color-validation-message-text;
  font-size: 1em;
  font-weight: normal;

  .validation-tooltip & {
    position: absolute;
    bottom: 100%;
  }
}

.validation-message::before {
  position: absolute;
  bottom: -12px;
  left: 47%;
  z-index: 99;
  border: solid;
  border-width: 12px 12px 0;
  border-color: $color-validation-message transparent;
  content: '';
}

@if $use-standard-validation-styles {
  .validation-border {
    // Disabled as validation HAS to override color
    // scss-lint:disable ImportantRule
    border: 1px solid $color-validation-message !important;
  }

  // Required or it fires VariableForProperty even if disabled...
  // scss-lint:disable all
  .validation-highlight {
    // Disabled as validation HAS to override color
    // scss-lint:disable ImportantRule
    color: $color-validation-message !important;
  }
}

@mixin inset-panel($panel-color: $color-partner-primary) {
  border: 1px solid $panel-color;
  border-radius: $site-border-radius;
  background: transparentize($panel-color, 0.95);
}

@mixin basic-panel-border($color: $color-grey-300) {
  border: 1px solid $color;
  border-bottom: 2px solid mix-with-black($color, 5%);
}

@mixin basic-panel($background: $color-background-panel, $border-color: $color-grey-300) {
  @include basic-panel-border($border-color);

  margin-bottom: $site-margin * 2;
  border-radius: $site-border-radius;
  background: $background;
}

@mixin basic-panel-header {
  margin: 0;
  border-top-left-radius: $site-border-radius;
  border-top-right-radius: $site-border-radius;
  background: $color-background-panel;
  padding: $site-margin;
  color: $color-panel-header;
  font-size: 14px * 1.3;
  font-weight: bold;
}

@mixin basic-panel-subheader {
  display: block;
  margin-top: calc($site-margin / 2);
  color: lighten($color-panel-header, 10%);
  font-size: $font-size-desktop;
  font-weight: normal;
}

@mixin basic-panel-content {
  border-top: 1px solid $color-grey-300;
  background: $color-background-panel;
}

@mixin content-container {
  @include outer-container;

  padding: 0 $site-margin $site-margin;
}

@mixin alert-variant($background, $border, $text-color) {
  border-color: $border;
  background-color: $background;
  color: $text-color;

  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link {
    color: darken($text-color, 10%);
  }
}

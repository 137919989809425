.manage-booking-wrapper {
  .text-content-header {
    background-image: url('https://static.carhire-solutions.com/images/wlp/jumbotron-road.jpg');

    .text-content-tagline {
      top: 40%;
      text-shadow: 1px 1px 0 #000;
    }
  }

  .card {
    margin-top: -30px;
    max-width: 400px;
  }
}

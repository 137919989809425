$car-panel-standard-margin: $site-margin !default;
$car-desktop-inner-top-padding: $car-panel-standard-margin * 2;
$car-desktop-features-spacing: ($car-panel-standard-margin * 3) !default;
$car-header-line-height: 14px !default;
$car-desktop-feature-height: 48px !default;
$car-image-height: 120px !default;
$car-image-width: 220px;
$color-package-button-shadow: none !default;
$hide-package-button-shadow: false !default;

@import 'component.rateCard';

.rate-card-with-price {
  @include clearfix;

  @include media($media-not-mobile) {
    // not the nicest but for now allows correcting the padding for only search results without restructuring the layout
    // TODO: structure layout so .car can have padding on all sides unless part of .rate-card-with-price
    margin-right: -$site-margin-half;
  }
}

.rate-card-with-price .car-inner-panel {
  float: left;
  width: 76.76824%; // stylelint-disable-line

  @include media($media-mobile) {
    @include span-columns(12);
  }
}

// Book button, price, offer ribbon/banner
.package-book-wrapper {
  @include span-columns(3);

  text-align: center;

  &.on-request {
    position: relative;
    padding-bottom: 20px;

    @include media($media-not-mobile) {
      padding-bottom: 25px;
    }
  }

  @include media($media-mobile) {
    @include span-columns(12);

    display: flex;
    float: none;
    text-align: left;
  }
}

.book-button-wrapper {
  display: inline-block;

  // Lined up with package information
  @include media($media-not-mobile) {
    margin-top: $car-image-height - $car-desktop-feature-height - $car-desktop-features-spacing;
  }

  // Right side of bottom panel
  @include media($media-mobile) {
    @include span-columns(6);

    align-self: flex-end;
    padding: $car-panel-standard-margin;
    height: $car-desktop-feature-height + ($car-panel-standard-margin * 2);
    text-align: center;
  }
}

.package-book-button {
  @include action-button;

  display: inline-block;

  // Padding top is lower to cater for the > icon (which is pushing out the size of the a by 2px)
  padding: $car-panel-standard-margin;
  line-height: $car-header-line-height;
  white-space: nowrap;
  font-size: $car-header-line-height;

  @if $hide-package-button-shadow == true {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  @include media($media-mobile) {
    // For whatever reason the above comment isn't true on mobile
    padding: $car-panel-standard-margin;
    width: 100%;
    height: 100%;
    line-height: $car-desktop-feature-height - ($car-panel-standard-margin * 2);
  }
}

.car-package-placeholder {
  padding: $car-header-line-height 0;
}

.car-package-placeholder,
.car-package-premium,
.car-package-special {
  margin-top: $car-desktop-inner-top-padding - ($car-header-line-height);
  line-height: $car-header-line-height;
  font-size: $car-header-line-height;

  @include media($media-mobile) {
    display: none;
  }
}

.car-package-inner {
  display: inline-block;
  padding: $car-header-line-height 0;
  text-align: center;
  line-height: $car-header-line-height;
  color: $color-white;

  @include media($media-not-mobile) {
    $car-package-ribbon-offset: 6px;

    position: relative;
    left: $car-package-ribbon-offset;
    border-top-left-radius: $car-panel-border-radius;
    border-top-right-radius: $car-panel-border-radius;
    border-bottom-left-radius: $car-panel-border-radius;
    width: 100%;

    &::before {
      position: absolute;
      top: $car-header-line-height * 3;
      right: 0;
      border: solid;
      border-width: 0 0 $car-package-ribbon-offset $car-package-ribbon-offset;
      content: '';
    }
  }

  @include media($media-mobile) {
    width: 100%;
  }
}

.car-package-premium .car-package-inner {
  background: $color-package-inclusive;

  &::before {
    border-color: transparent transparent transparent darken($color-package-inclusive, 10%);
  }
}

.car-package-special .car-package-inner {
  background: $color-package-special;

  &::before {
    border-color: transparent transparent transparent darken($color-package-special, 10%);
  }
}

.car-on-request {
  display: block;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #dadada;
  padding-top: 5px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;

  @include media($media-not-mobile) {
    border: 0;
    padding-top: 0;
  }
}

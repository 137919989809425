$max-width: 1048px !default;
$site-margin: 12px !default;
$site-margin-half: calc($site-margin / 2);
$site-margin-double: ($site-margin * 2);
$site-border-radius: 4px !default;

$border-box-sizing: true;

$font-family-main: 'Lato', sans-serif !default;
$font-size-mobile: 16px;
$font-size-desktop: 12.5px !default;
$font-main-mobile: $font-size-mobile $font-family-main;
$font-main-desktop: $font-size-desktop $font-family-main;

$header-height: 50px !default; // new default value will be 65px, check demo partner
$header-height-mobile: null !default;
$footer-height: 50px !default;
$sidebar-width: 260px !default;

$default-input-height: ($site-margin * 3.5) !default;

$input-border-radius: $site-border-radius !default;

$default-container-padding: $site-margin !default;

$site-small-search-page: $max-width < 950px !default;

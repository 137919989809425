$color-car-feature-icon: $color-colored-icon !default;

@mixin package-title-banner($background-color) {
  border-top: 0;
  background: $background-color;
  padding-bottom: $car-panel-standard-margin;
  color: contrast-color($background-color);
  font-weight: normal;
}

.package-more-info {
  @include row;

  cursor: pointer;

  .package-title {
    margin: $car-panel-standard-margin 0 0 0;
    padding-left: $car-panel-standard-margin;

    i {
      float: right;
      margin-right: $car-panel-standard-margin;
    }

    &.inclusive-header {
      color: $color-package-inclusive;
    }

    &.special-offer-header {
      color: $color-package-special;
    }

    @include media($media-mobile) {
      margin: 0;
      padding: $car-panel-standard-margin $car-panel-standard-margin 0;
      text-align: center;

      &.special-offer-header {
        @include package-title-banner($color-package-special);
      }

      &.inclusive-header {
        @include package-title-banner($color-package-inclusive);
      }
    }
  }

  @include media($media-mobile) {
    border: 2px solid $color-package-info-border;
    border-width: 2px 0 0;

    .special-offer &,
    .inclusive & {
      border-top: 0;
    }
  }
}

.package-more-info:nth-child(n + 2) {
  margin-top: $site-margin;
}

.package-description {
  padding: calc($site-margin / 2) $site-margin;
  font-style: italic;

  @include media($media-mobile) {
    margin-top: $site-margin;
    font-size: 11px;
  }
}

.extra-charges {
  @include row;

  margin: 0 $car-panel-standard-margin;
  padding: $car-panel-standard-margin 0 0;
  list-style: none;
}

.extra-charge,
.extra-inclusion {
  @include span-columns(12);
  @include omega(n);

  margin-bottom: $car-panel-standard-margin;

  @include media($media-mobile) {
    font-size: $car-header-line-height - 1;
  }
}

.extra-charge i {
  color: $color-black;
}

.extra-inclusion i {
  color: $color-car-feature-icon;
}

@mixin colored-inclusions($inclusion-color) {
  .extra-inclusion,
  .inclusion-detail {
    i {
      color: $inclusion-color;
    }
  }
}

.extra-charges.inclusive,
.package.inclusive {
  @include colored-inclusions($color-package-inclusive);
}

.special-offer-more-info {
  @include colored-inclusions($color-package-special);

  border-bottom: 1px solid $color-package-special;
}

.charge-details {
  margin-top: calc($car-panel-standard-margin / 4);
  margin-left: calc($car-panel-standard-margin / 2);
  border-left: 1px solid $color-grey-300;
  padding-left: calc($car-panel-standard-margin / 2);
  font-size: 0.9em;
}

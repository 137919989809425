@import 'functions/color';

$color-grey-0: #fff !default;
$color-white: $color-grey-0 !default;
$color-grey-50: #fafafa !default;
$color-grey-100: #f5f5f5 !default;
$color-grey-150: #f2f2f2 !default;
$color-grey-200: #eee !default;
$color-grey-300: #e0e0e0 !default;
$color-grey-400: #bdbdbd !default;
$color-grey-500: #9e9e9e !default;
$color-grey-600: #757575 !default;
$color-grey-700: #616161 !default;
$color-grey-800: #424242 !default;
$color-grey-900: #212121 !default;
$color-black: #000 !default;

$color-text-dark: rgb(0 0 0 / 87%) !default;
$color-text-dark-secondary: rgb(0 0 0 / 54%) !default;
$color-text-dark-disabled: rgb(0 0 0 / 38%) !default;
$color-text-dark-divider: rgb(0 0 0 / 12%) !default;

$color-text-light: rgb(255 255 255 / 100%) !default;
$color-text-light-secondary: rgb(255 255 255 / 70%) !default;
$color-text-light-disabled: rgb(255 255 255 / 30%) !default;
$color-text-light-dividers: rgb(255 255 255 / 12%) !default;

$color-error: #dd1c1a !default;

$color-partner-primary: #767676 !default;
$color-partner-secondary: #8fa51b !default;

$color-secondary-button-background: $color-white !default;
$color-secondary-button: $color-partner-secondary !default;

// static colours (these should remain the same for all partners)
$color-error: #dd1c1a !default;
$color-positive: #10ad12 !default;
$color-focus: #e3ab31 !default;
$color-urgent: #db2118 !default;
$color-warning: #db2118 !default;

// static colours for packages
$color-package-basic: #e3ab31 !default;
$color-package-inclusive: $color-positive !default;
$color-package-special: #0abad6 !default;
$color-discount: $color-urgent !default;

// static colours for backgrounds
$color-background-main: $color-white !default;
$color-background-view: $color-grey-50 !default;
$color-background-panel: $color-white !default; // don't change this or backgrounds on images may be visible
$color-background-panel-subdued: $color-grey-100 !default;
$color-background-sidebar: $color-grey-200 !default;
$color-header-background: $color-partner-primary !default;
$color-text-main: $color-text-dark !default;
$color-text-inverted: $color-text-light !default;

$color-info-button: $color-partner-primary !default;
$color-info-button-background: $color-background-panel !default;
$color-info-button-inactive: $color-grey-400 !default;
$color-info-button-inactive-background: $color-grey-200 !default;
$color-info-header: tint-text($color-partner-primary, 80%) !default;
$color-panel-header: tint-text($color-partner-primary, 55%) !default;
$color-subdued-button-background: $color-grey-300 !default;
$color-subdued-button: constrast-color($color-subdued-button-background) !default;

// dynamic or potentially dynamic colours
$color-call-to-action: $color-partner-primary !default;
$color-call-to-action-hover: action-button-background($color-call-to-action, 'hover') !default;
$color-call-to-action-pressed: action-button-background($color-call-to-action, 'pressed') !default;
$color-call-to-action-text: contrast-color($color-call-to-action) !default;
$color-call-to-action-hover-text: contrast-color($color-call-to-action-hover) !default;
$color-call-to-action-pressed-text: contrast-color($color-call-to-action-pressed) !default;
$color-secondary-button: $color-partner-secondary !default;
$color-secondary-button-hover: mix-with-black($color-secondary-button, 10%) !default;
$color-loading-pulse: transparentize($color-partner-primary, 0.5) !default;
$color-colored-icon: $color-grey-800 !default;
$color-input-icon: $color-grey-300 !default;
$color-input-icon-background: $color-partner-primary !default;
$color-footer-background: $color-background-main !default;
$color-footer: contrast-color($color-footer-background) !default;
$color-link: $color-partner-primary !default;

// to be removed
$color-text-header: $color-text-light !default;
$color-text-footer: $color-text-dark !default;

// state colors
$color-success-text: #3c763d !default;
$color-success-bg: #dff0d8 !default;
$color-success-border: darken(adjust-hue($color-success-bg, -10), 5%) !default;

$color-info-text: #31708f !default;
$color-info-bg: #d9edf7 !default;
$color-info-border: darken(adjust-hue($color-info-bg, -10), 7%) !default;

$color-warning-text: #8a6d3b !default;
$color-warning-bg: #fcf8e3 !default;
$color-warning-border: darken(adjust-hue($color-warning-bg, -10), 5%) !default;

$color-danger-text: #a94442 !default;
$color-danger-bg: #f2dede !default;
$color-danger-border: darken(adjust-hue($color-danger-bg, -10), 5%) !default;

$color-lightbox-overlay: rgb(0 0 0 / 80%);
$lightbox-button-bottom-border: calc($site-margin / 3);
$lightbox-padding: calc($site-margin / 2);

$tabs-box-shadow: inset 0 -5px 8px -8px #c1c1c1;
$tab-item-background-hover: #f5f5f5;
$tab-item-padding-y: 1rem;
$tab-item-padding-x: 0.4rem;

$modal-footer-bg-color: #d9f7ff; // color.adjust($color-partner-primary, $lightness: 46%);

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: $color-lightbox-overlay;
  width: 100%;
  height: 100%;
  text-align: center;

  &.hidden {
    display: none;
  }
}

.lightbox-container {
  position: relative;
}

.lightbox-vertical-center {
  @include media($media-mobile) {
    height: 100%;
  }

  @include media($media-not-mobile) {
    @include vertical-center;

    margin: auto;
  }
}

.lightbox-inner {
  background: $color-background-panel;
  overflow: hidden;

  @include media($media-mobile) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
  }

  @include media($media-not-mobile) {
    display: inline-block;
    border-radius: $site-border-radius;
  }
}

.lightbox-fixed-height {
  margin: auto;

  .lightbox-content {
    width: auto;
    text-align: center;
    -webkit-overflow-scrolling: touch;

    @include media($media-not-mobile) {
      max-height: 500px;
    }
  }
}

.lightbox-fixed-width {
  display: block;
  margin: auto;
  max-width: 500px;

  .lightbox-content {
    width: 100%;
  }
}

.lightbox-content {
  max-height: 100%;
  overflow-y: auto;

  @include media($media-mobile) {
    padding: 42px 0; // Same height as the close button!
  }
}

.lightbox-loading {
  margin: 0 auto;
  width: 100px;
  height: 100px;

  .cnx-loading {
    display: block;
  }
}

.lightbox-close-button {
  @include span-columns(12);

  z-index: 999;
  border-bottom: $lightbox-button-bottom-border solid $color-info-button-background;
  background: $color-info-button-background;
  cursor: pointer;
  padding: ($lightbox-padding * 1.5) 0 (($lightbox-padding * 1.5) - $lightbox-button-bottom-border);
  text-align: center;
  color: $color-info-button;

  &:hover {
    border-bottom: $lightbox-button-bottom-border solid $color-info-button;
    color: $color-info-button;
  }

  @include media($media-not-mobile) {
    display: none;
  }

  @include media($media-mobile) {
    position: absolute;
    bottom: 0;
  }
}

.lightbox-title-bar {
  @include row;

  border-bottom: 1px solid $color-grey-300;
  background: $color-background-panel;
  cursor: pointer;
  padding: ($lightbox-padding * 1.5) $site-margin;
  text-align: left;

  .lightbox-title {
    float: none;
    font-weight: bold;
  }

  .lightbox-desktop-close-button {
    float: right;
  }

  &:hover .lightbox-desktop-close-button {
    color: $color-call-to-action;
  }

  @include media($media-mobile) {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    text-align: center;

    .lightbox-desktop-close-button {
      display: none;
    }
  }
}

.lightbox-alternative {
  text-align: left;
  line-height: 1.5;
  font-size: 0.78125rem; // stylelint-disable-line

  .lightbox-vertical-center {
    height: 100%;

    .widget-body & {
      position: relative;
    }

    @include media($media-not-mobile) {
      height: 600px;
    }
  }

  .lightbox-inner {
    display: flex;
    position: static;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 0;
    width: 100%;
    max-width: 800px;
    height: 100%;

    @include media($media-not-mobile) {
      margin: 1.75rem auto;
    }
  }

  .lightbox-title-bar {
    position: static;
    background: $color-partner-primary;
    text-align: left;
    color: #fff;
    font-size: 110%;
    font-weight: 400;

    &:hover .lightbox-desktop-close-button {
      color: #fff;
    }

    .lightbox-desktop-close-button {
      display: block;
    }
  }

  .lightbox-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;

    .modal-body {
      padding: 0;
      overflow: hidden;
    }

    .modal-footer {
      border-right: 0;
      border-left: 0;
      border-top-width: 0;
      border-radius: 0;
      box-shadow: 0 -2px 5px -1px #d5d5d5;
      background: $modal-footer-bg-color;
      padding: 0;
    }
  }

  .lightbox-close-button {
    display: none;
  }
}

.tabs-in-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab-content {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
}

.nav-tabs {
  align-items: flex-start;
  flex-wrap: nowrap;
  box-shadow: $tabs-box-shadow;
  background-color: rgb(240 240 240);

  .nav-link {
    transition: none;
    border: none;
    padding: $tab-item-padding-y $tab-item-padding-x;
    min-width: 60px;
    max-width: 110px;
    height: 100%;
    text-align: center;
    color: $gray-600;
    overflow-wrap: break-word;
    hyphens: auto;

    &.active {
      border-right: 1px solid $nav-tabs-border-color;
      border-left: 1px solid $nav-tabs-border-color;
      border-radius: 0;
      padding-right: calc(0.4rem + 1px);
      padding-left: calc(0.4rem + 1px);
      color: #02bceb;
    }

    &:hover:not(.active) {
      border-radius: 0;
      box-shadow: $tabs-box-shadow;
      background-color: $tab-item-background-hover;
    }

    .tab-icon {
      display: block;
      font-size: 1.5rem;
    }

    .tab-title {
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    @include media-breakpoint-up(sm) {
      padding: calc(#{$tab-item-padding-y} / 2) calc(#{$tab-item-padding-x} + 2px);
      min-width: 90px;
    }
  }

  .nav-item:first-child {
    .nav-link.active {
      border-left: 0;
      padding-left: calc(0.4rem + 2px);
    }
  }
}

$maintenance-header-background: $color-header-background !default;

.maintenance {
  background: #eff1ee;

  .animate-root,
  .view-root {
    background: #eff1ee;
  }

  h1 {
    font-size: 30px;
  }

  article {
    font-size: 15px;
  }

  .view-container {
    margin: 25px auto 0;
    padding: 0;
    max-width: 760px;
    text-align: center;
  }

  .view-container::before {
    box-shadow: none;
  }

  .content {
    background-color: $maintenance-header-background;

    img {
      max-width: 300px;
      max-height: 50px;
    }
  }

  .content-alternative {
    background-color: lighten($color-partner-primary, 70%);
  }
}

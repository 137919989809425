.reservation-failure-contact-wrapper {
  .reservation-failure-subtext {
    margin-bottom: 1em;
  }

  .contact-detail {
    display: block;
    margin: 8px 0;
    text-align: center;
    font-weight: bold;
  }
}

.reservation-failure {
  .api-error-paragraph {
    margin: 20px;
    padding: 12px;
  }

  .api-error-button {
    @include action-button;

    border: 1px solid $color-partner-primary;
    padding: 10px 12px;
    line-height: 14px;
    font-size: 14.5px;
  }

  .api-error-button.home-btn {
    background: #fff;
    color: $color-partner-primary;
  }
}

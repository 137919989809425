$promo-background-image: url('../images/bg-autumn-2017.jpg');
$promo-background-overlay-gradient-top: rgb(0 0 0 / 40%);
$promo-background-overlay-gradient: linear-gradient(to bottom, $promo-background-overlay-gradient-top 20%, $background-overlay-gradient-bottom 50%);

@include media($media-not-mobile) {
  .landing-container {
    &.promo-november-2018 {
      background-image: $promo-background-image;
      background-image: $promo-background-overlay-gradient, $promo-background-image;

      .homepage-marketing-copy-wrapper {
        display: none;
      }
    }
  }
}

.promotion-banner-wrapper {
  margin: 20px 0;
  background-color: rgb(0 0 0 / 50%);
  padding: 20px;
  text-align: center;

  h2,
  h3,
  p {
    margin: 0;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 1.8em;
  }

  h3 {
    margin-bottom: 15px;
    font-size: 1.3em;

    span {
      vertical-align: -0.13em;
      color: #f2b119;
      font-size: 1.4em;
    }
  }

  p {
    font-size: 1em;
  }

  @include media($media-not-mobile) {
    margin: 0 0 80px;
    background: none;
    padding: 50px 0 0;
    text-align: center;

    h2 {
      margin-bottom: 15px;
      font-size: 3.5em;
    }

    h3 {
      font-size: 2.8em;
    }
  }

  @include media($media-tablet) {
    margin-bottom: 50px;
    text-align: center;

    h2 {
      margin-bottom: 10px;
      font-size: 1.7em;
    }

    h3 {
      font-size: 1.5em;
    }
  }
}

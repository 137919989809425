$color-confirm-dialog-background: $color-grey-100 !default;
$color-confirm-dialog-link: $color-link !default;
$color-confirm-dialog-border: $color-grey-300 !default;
$color-confirm-dialog-header: $color-text-main !default;

.confirmation-dialog {
  margin: 0;
  background-color: $color-confirm-dialog-background;
  padding: 20px;
  text-align: left;

  .modal-confirm-button-wrapper {
    margin-top: 20px;
    text-align: center;
  }

  .modal-confirm-button {
    @include action-button;

    display: inline-block;
    background-color: $color-partner-primary;
    padding: 10px 20px;
    line-height: normal;
  }

  .modal-link-button {
    display: inline-block;
    text-decoration: none;
    color: $color-partner-primary;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media($media-mobile) {
    background-color: #fff;
  }

  @include media($media-portable) {
    width: 100%;
  }
}

.lightbox-refresh-search .lightbox-inner {
  width: 80%;
  max-width: 800px;

  @include media($media-mobile) {
    width: 100%;
  }
}

$color-api-error: $color-error !default;
$color-api-error-text: $color-white !default;
$color-payment-error: #cf1e1b !default;

.api-error {
  margin: 20px 0;
  border-radius: $site-border-radius;
  background: $color-api-error;
  padding: 12px;
  text-align: center;
  color: $color-api-error-text;
}

.payment-error {
  display: none;
  align-items: flex-start;
  flex-direction: row;
  margin: 15px 0;
  border-radius: $site-border-radius;
  background: $color-payment-error;
  padding: 15px;
  text-align: left;
  color: $color-api-error-text;

  &.payment-failed {
    display: flex;

    .failed {
      display: block;
    }
  }

  &.payment-cancelled {
    display: flex;

    .cancelled {
      display: block;
    }
  }

  .failed,
  .cancelled {
    display: none;
  }

  .icon-warning {
    display: inline-block;
    margin-right: 15px;
    font-size: 220%;
  }

  h3 {
    margin: 0 0 10px;
  }

  p {
    margin: 0;
  }
}

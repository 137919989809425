.cnx-loading {
  @include loader;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200deg 20% 80%);
  }

  100% {
    background-color: hsl(200deg 20% 95%);
  }
}

@mixin vertical-center {
  // Be aware that vertical-center will reset the z-index of the element!
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin fixed-footer($footer-height) {
  .root {
    position: relative;
    min-height: 100%;
  }

  .homepage-marketing-container,
  .view-root {
    margin-bottom: $footer-height;
  }

  .footer {
    position: absolute;
    bottom: 0;
  }
}

@mixin accordion-toggle {
  cursor: pointer;
  user-select: none;
}

@mixin accordion-content {
  display: none;

  &.visible {
    display: block;
  }
}

@mixin content-with-icon-content {
  display: block;
  float: none;
  overflow: hidden;
}

@mixin content-with-icon-icon {
  display: block;
  float: left;
}

.text-content {
  @include outer-container;

  background: $color-white;
  padding: $site-margin;
}

.text-content-header {
  @include outer-container;

  height: 150px;

  .text-content-tagline {
    @include vertical-center;

    margin: 0;
    width: 100%;
    text-align: center;
    color: $color-white;
  }
}

.jumbotron {
  @include content-container;

  background: $color-white;
  height: 200px;

  h1 {
    float: left;
    margin: 20px 0 0 (-$site-margin);
    background: $color-partner-secondary;
    padding: 10px $site-margin * 3;
    font-size: 1.8em;

    @include media($media-not-portable) {
      margin-left: (-$site-margin) * 2;
    }
  }

  a {
    color: $color-link-footer;
  }
}

.content {
  @include content-container;

  box-shadow: 0 10px 15px -5px #d9d9d9;
  background: $color-white;
  padding: $site-margin $site-margin * 2;

  h1 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 2.5em;

    @include media($media-not-portable) {
      margin-bottom: 0.75em;
    }
  }

  a {
    color: $color-partner-primary;
  }

  p {
    line-height: 1.6em;
  }

  ul,
  ol {
    line-height: 1.6em;
  }

  table {
    line-height: 1.6em;

    th {
      text-align: left;
    }
  }

  .image-frame {
    margin: 0 0 $site-margin;
    border: 3px solid #fff;
    box-shadow: 1px 2px 2px 1px #ccc;
    max-width: 100%;

    @include media($media-not-portable) {
      float: right;
      margin: $site-margin 0 $site-margin * 2 $site-margin * 2;
      max-width: 350px;

      &.align-left {
        float: left;
      }
    }
  }

  @include media($media-not-portable) {
    padding: $site-margin * 3 $site-margin * 4;
  }
}

$loader-size: 1em;

@mixin loader-circle($size: $loader-size) {
  border-radius: 50%;
  width: $size;
  height: $size;
  animation: load 1.8s infinite ease-in-out;
  animation-fill-mode: both;
}

@mixin loader($color-loading: $color-text-main, $size: $loader-size) {
  @include loader-circle($size);

  display: none;
  transform: translateZ(0);
  margin: $size auto $size * 3;
  animation-delay: -0.16s;
  color: $color-loading;
  font-size: 16px;

  @keyframes load {
    0%,
    80%,
    100% {
      box-shadow: 0 $size 0 -1.3em;
    }

    40% {
      box-shadow: 0 $size 0 0;
    }
  }

  &.visible {
    display: block;
  }

  &::before,
  &::after {
    @include loader-circle($size);

    position: absolute;
    top: 0;
    content: '';
  }

  &::before {
    left: -($size * 1.5);
    animation-delay: -0.32s;
  }

  &::after {
    left: $size * 1.5;
  }
}
